<template>
  <v-dialog
     v-if="CUSTOMER !== 'ROGERS'"
     :key="storeSettingVisible"
     v-model="storeSettingVisible"
     width="850"
     scrollable
     persistent
   >
     <template v-slot:activator="{ on, attrs }">
       <v-btn
         :disabled="buttonDisabled"
         @click="showStoreSettingData()"
         text
         v-bind="attrs"
         v-on="on"
         ><img src="@/assets/img/ico-setting.png" alt=""
       /></v-btn>
     </template>
     <v-card class="popup" >
       <v-card-title>
       <h3 >
         <i class="ico ico-store"></i>{{ $t("Store Setting") }}
       </h3>
     </v-card-title>
     <v-card-text >
         <v-row v-for="(inputs, idx) in storeInputs" :key="idx">
           <v-col
             v-for="(input, colIdx) in inputs"
             :key="input.key"
             class="pt-2"
               cols="4"
           >
             <v-select
               v-if="input.selectable"
               v-model="input.value"
               :ref="input.key"
               :disabled="input.disabled"
               @change="
                 setSelectableAreaNames(idx);
                 setConditionsforChLbs(input);
               "
               :items="input.options"
               :class="getStoreInputSelectClass(input.name)"
               :label="`${$t(input.label)}*`"
               :placeholder="getPlaceholder(input)"
               outlined
               dense
               hide-details
               clearable
               attach
               @keydown="clearTabindex"
             >
             </v-select>
             <v-text-field
               v-model="input.value"
               :disabled="input.disabled || areaAddDisabled"
               :class="getStoreInputTextClass(input.key, input.key === 'countryInput' ||
                       input.key === 'regionInput' ||
                       input.key === 'cityInput'
                       ? 1
                       : idx)"
               :label="`${$t(input.label)}`"
               :placeholder="getPlaceholder(input)"
               v-else-if="!input.selectable && input.key === 'storeDescription'"
               outlined
               dense
               hide-details
               clearable
               @keypress="handleKeyUpspl"
               @keydown="clearTabindex"
             >
               <template v-if="isAreaInput(input.key)" v-slot:append>
                 <v-btn
                   :disabled="areaAddDisabled"
                   @click="addAreaValue(input.value, idx, colIdx - 1, input.key)"
                   text
                 >
                   <img src="@/assets/img/btn-save.jpg" alt="" />
                 </v-btn>
               </template>
             </v-text-field>
             <v-text-field
               v-model="input.value"
               :ref="input.key"
               :disabled="input.disabled || areaAddDisabled"
               :class="getStoreInputTextClass(input.key, idx)"
               :label="`${$t(input.label)}*`"
               :placeholder="getPlaceholder(input)"
               v-else
               outlined
               dense
               hide-details
               clearable
               @keypress="handleKeyUpspl"
               @keydown="clearTabindex"
             >
               <template v-if="isAreaInput(input.key)" v-slot:append>
                 <v-btn
                   :disabled="areaAddDisabled"
                   @click="addAreaValue(input.value, idx, colIdx - 1, input.key)"
                   text
                 >
                   <img src="@/assets/img/btn-save.jpg" alt="" />
                 </v-btn>
               </template>
             </v-text-field>
           </v-col>
         </v-row>
         <v-expansion-panels
           flat
           accordion
           class="advancedSetting"
           v-model="advancedSettingVisible"
         >
           <v-expansion-panel>
             <v-expansion-panel-header class="pl-0 pr-0"
               ><i class="throwline"></i
               >{{ $t("Show Advanced Setting") }}</v-expansion-panel-header
             >
             <v-expansion-panel-content>
               <v-row v-for="(advInputs, idx) in advStoreInputs" :key="idx">
                 <v-col
                   v-for="advInput in advInputs"
                   :key="advInput.name"
                   class="pt-1 pb-1"
                   cols="4"
                 >
                   <v-select
                     v-if="advInput.selectable"
                     :label="$t(advInput.label)"
                     :placeholder="getPlaceholder(advInput)"
                     :items="advInput.options"
                     v-model="advInput.value"
                     :class="getStoreInputSelectClass(advInput.name)"
                     outlined
                     dense
                     hide-details
                     clearable
                     @keydown="clearTabindex"
                   ></v-select>
                   <v-text-field
                     v-else
                     v-model="advInput.value"
                     @keyup.prevent="handleKeyUp"
                     :min="advInput.min ? advInput.min : -100000"
                     :max="advInput.max ? advInput.max : 100000"
                     class="form-input"
                     :disabled="advInput.disabled"
                     :label="$t(advInput.label)"
                     :placeholder="getPlaceholder(advInput)"
                     outlined
                     dense
                     hide-details
                     type="number"
                   >
                   </v-text-field>
                 </v-col>
               </v-row>
               <!-- <div v-if="rabbitMqEnabled==='false'"> -->
                <v-row v-if="notifications">
        <v-col cols="12">
        <h3 class="mt-5" style="padding-left: 0px;color: #0A2640;line-height: 1;font-weight: bold;">
          <u>{{ $t('SERVER SENT EVENT SETTINGS') }}</u></h3>
        <div class="row">
           <v-col>
          <div class="ml-1" style="margin-top: 24px;">
            <span v-if="notifications">
            <v-label> {{ $t('Picking') }} &nbsp;: </v-label>
            <v-switch inset hide-details solo class="form-switch"  v-model="picking" style="margin-left: 10px;
             vertical-align: sub;display: inline-block;"></v-switch>
            </span>
            <span class="ml-4" v-if="notifications">
              <v-label> {{ $t('Alarm') }} &nbsp;: </v-label>
              <v-switch inset hide-details solo class="form-switch"  v-model="alarm" style="margin-left: 10px;
              vertical-align: sub;display: inline-block;"></v-switch>
            </span>
          </div>
        </v-col>
        <v-col cols="7" class="mt-3">
          <v-text-field
                    class="form-input"
                    :label="$t('SSE Event URL for Subscription Details')"
                     placeholder="URL"
                     outlined
                     dense
                     hide-details
                     v-model="sseUrl"
                     disabled
                   >
          </v-text-field>
        </v-col>
        </div>

      </v-col>
    </v-row>
  <!-- </div> -->
             </v-expansion-panel-content>
           </v-expansion-panel>
         </v-expansion-panels>
       </v-card-text>

       <v-card-actions class="d-flex justify-center">
         <v-btn text icon @click="handleSaveBtnClick" class="btn">{{
           $t("Save")
         }}</v-btn>
         <v-btn text icon @click="cancelStoreSetting()" class="btn">{{
           $t("Cancel")
         }}</v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
   <v-dialog
     v-else
     v-model="storeSettingVisible"
     width="850"
     scrollable
     persistent
   >
     <template v-slot:activator="{ on, attrs }">
       <v-btn
         :disabled="buttonDisabled"
         @click="showStoreSettingData()"
         text
         v-bind="attrs"
         v-on="on"
         ><img src="@/assets/img/ico-setting.png" alt=""
       /></v-btn>
     </template>
     <v-card class="popup">
       <v-card-title>
         <h3><i class="ico ico-store"></i>{{ $t("Store Setting") }}</h3>
       </v-card-title>

       <v-card-text >
           <v-row v-for="(inputs, idx) in newStoreInputs" :key="idx">
             <v-col
               v-for="(input, colIdx) in inputs"
               :key="input.key"
               class="pt-2"
               cols="4"
             >
               <v-select
                 v-if="input.selectable"
                 v-model="input.value"
                 :ref="input.key"
                 :disabled="input.disabled"
                 @change="
                   setSelectableAreaNames(idx);
                   setConditionsforChLbs(input);
                 "
                 :items="input.options"
                 :class="getStoreInputSelectClass(input.name)"
                 :label="`${$t(input.label)}*`"
                 :placeholder="getPlaceholder(input)"
                 outlined
                 dense
                 hide-details
                 clearable
                 attach
                 @keydown="clearTabindex"
               >
               </v-select>
               <v-text-field
                 v-model="input.value"
                 :ref="input.key"
                 :disabled="input.disabled || areaAddDisabled"
                 :class="getStoreInputTextClass(input.key, idx)"
                 :label="`${$t(input.label)}`"
                 :placeholder="getPlaceholder(input)"
                 v-else-if="
                   !input.selectable && input.key === 'storeDescription'
                 "
                 outlined
                 dense
                 hide-details
                 clearable
                 @keypress="handleKeyUpspl"
                 @keydown="clearTabindex"
               >
                 <template v-if="isAreaInput(input.key)" v-slot:append>
                   <v-btn
                     :disabled="areaAddDisabled"
                     @click="
                       addAreaValue(input.value, idx, colIdx - 1, input.key)
                     "
                     text
                   >
                     <img src="@/assets/img/btn-save.jpg" alt="" />
                   </v-btn>
                 </template>
               </v-text-field>
               <v-text-field
                 v-model="input.value"
                 :ref="input.key"
                 :disabled="input.disabled || areaAddDisabled"
                 :class="getStoreInputTextClass(input.key, idx)"
                 :label="`${$t(input.label)}*`"
                 :placeholder="getPlaceholder(input)"
                 v-else
                 outlined
                 dense
                 hide-details
                 clearable
                 @keypress="handleKeyUpspl"
                 @keydown="clearTabindex"
               >
                 <template v-if="isAreaInput(input.key)" v-slot:append>
                   <v-btn
                     :disabled="areaAddDisabled"
                     @click="
                       addAreaValue(input.value, idx, colIdx - 1, input.key)
                     "
                     text
                   >
                     <img src="@/assets/img/btn-save.jpg" alt="" />
                   </v-btn>
                 </template>
               </v-text-field>
             </v-col>
           </v-row>
           <v-expansion-panels
             flat
             accordion
             class="advancedSetting"
             v-model="advancedSettingVisible"
           >
             <v-expansion-panel >
               <v-expansion-panel-header class="pl-0 pr-0"
                 ><i class="throwline"></i
                 >{{ $t("Show Advanced Setting") }}</v-expansion-panel-header
               >
               <v-expansion-panel-content>
                 <v-row v-for="(advInputs, idx) in advStoreInputs" :key="idx">
                   <v-col
                     v-for="advInput in advInputs"
                     :key="advInput.name"
                     class="pt-1 pb-1"
                     cols="4"
                   >
                     <v-select
                       v-if="advInput.selectable"
                       :label="$t(advInput.label)"
                       :placeholder="getPlaceholder(advInput)"
                       :items="advInput.options"
                       v-model="advInput.value"
                       :class="getStoreInputSelectClass(advInput.name)"
                       outlined
                       dense
                       hide-details
                       clearable
                       @keydown="clearTabindex"
                     ></v-select>
                     <v-text-field
                       v-else
                       v-model="advInput.value"
                       @keyup.prevent="handleKeyUp"
                       :min="advInput.min ? advInput.min : -100000"
                       :max="advInput.max ? advInput.max : 100000"
                       class="form-input"
                       :disabled="advInput.disabled"
                       :label="$t(advInput.label)"
                       :placeholder="getPlaceholder(advInput)"
                       outlined
                       dense
                       hide-details
                       type="number"
                     >
                     </v-text-field>
                   </v-col>
                 </v-row>
                 <!-- <div v-if="rabbitMqEnabled==='false'"> -->
                 <v-row v-if="notifications">
        <v-col cols="12">
        <h3 class="mt-5" style="padding-left: 0px;color: #0A2640;line-height: 1;font-weight: bold;">
          <u>{{ $t('SERVER SENT EVENT SETTINGS') }}</u></h3>
        <div class="row">
           <v-col>
          <div class="ml-1" style="margin-top: 24px;">
            <span v-if="notifications">
            <v-label> {{ $t('Picking') }} &nbsp;: </v-label>
            <v-switch inset hide-details solo class="form-switch"  v-model="picking" style="margin-left: 10px;
             vertical-align: sub;display: inline-block;"></v-switch>
            </span>
            <span class="ml-4" v-if="notifications">
              <v-label> {{ $t('Alarm') }} &nbsp;: </v-label>
              <v-switch inset hide-details solo class="form-switch"  v-model="alarm" style="margin-left: 10px;
              vertical-align: sub;display: inline-block;"></v-switch>
            </span>
          </div>
        </v-col>
        <v-col cols="7" class="mt-3">
          <v-text-field
                    class="form-input"
                    :label="$t('SSE Event URL for Subscription Details')"
                     placeholder="URL"
                     outlined
                     dense
                     hide-details
                     v-model="sseUrl"
                     disabled
                   >
          </v-text-field>
        </v-col>
        </div>

      </v-col>
    </v-row>
    <!-- </div> -->
               </v-expansion-panel-content>
             </v-expansion-panel>
           </v-expansion-panels>

 </v-card-text>
         <v-card-actions class="d-flex justify-center">
           <v-btn text icon @click="handleSaveBtnClick()" class="btn">{{
             $t("Save")
           }}</v-btn>
           <v-btn text icon @click="cancelStoreSetting()" class="btn">{{
             $t("Cancel")
           }}</v-btn>
         </v-card-actions>

     </v-card>
   </v-dialog>
 </template>

<script>
// 이 소스에 대한 설명은 StorePopupAddSummary의 <script> 태그를 참고.
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import StorePopup from '@/mixins/StorePopup'
import configs from '@/plugins/configs'

export default {
  mixins: [StorePopup],
  props: {
    user: {
      type: Object
    },
    store: {
      type: Object
    },
    buttonDisabled: {
      type: Boolean
    },
    areaAddDisabled: {
      type: Boolean
    }
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      storeSettingVisible: false,
      countries: {},
      advancedSettingVisible: 1,
      CUSTOMER: configs.Customer,
      storeInputs: [
        [
          // 0
          {
            name: 'Company',
            key: 'company',
            label: 'Company',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select',
            disabled: true
          }

        ],
        // 1
        [
          {
            name: 'Country',
            key: 'country',
            label: 'Country',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Region',
            key: 'region',
            label: 'Region',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'City',
            key: 'city',
            label: 'City',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          }
        ],
        [
          // 2
          {
            name: 'Country',
            key: 'countryInput',
            label: 'Country',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Region',
            key: 'regionInput',
            label: 'Region',
            value: '',
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'City',
            key: 'cityInput',
            label: 'City',
            value: '',
            selectable: false,
            placeholderType: 'input'
          }
        ],
        [
          // 3
          {
            name: 'Store Name',
            key: 'storeName',
            label: 'Store Name',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Store Code',
            key: 'store',
            label: 'Store Code',
            value: null,
            selectable: false,
            placeholderType: 'input',
            disabled: true
          },
          {
            name: 'Time zone',
            key: 'zoneId',
            label: 'Time zone',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select',
            disabled: true
          }
        ],
        [
          // 4
          // {
          //   name: 'Store Description',
          //   key: 'storeDescription',
          //   label: 'Store Description',
          //   value: '',
          //   selectable: false,
          //   placeholderType: 'input'
          // },

          {
            name: 'Time Server',
            key: 'ipNtpServer',
            label: 'Time Server',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Whitelist',
            key: 'whiteListEnable',
            label: 'Whitelist',
            value: 'Enabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          },
          {
            name: 'LBS',
            key: 'lbsEnabled',
            label: 'LBS',
            value: 'Disabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }
        ],
        [
          // 5

          {
            name: 'Store Description',
            key: 'storeDescription',
            label: 'Store Description',
            value: '',
            selectable: false,
            placeholderType: 'input'
          }
        ]
      ],
      newStoreInputs: [
        // 0
        [
          {
            name: 'Company',
            key: 'company',
            label: 'Company',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select',
            disabled: true
          },
          {
            name: 'Market Landscape ID',
            key: 'store',
            label: 'marketLandscape ID',
            value: null,
            selectable: false,
            placeholderType: 'input',
            disabled: true
          },
          {
            name: 'Store Name',
            key: 'storeName',
            label: 'Store Name',
            value: null,
            selectable: false,
            placeholderType: 'input'
          }
        ],
        // 1
        [
          {
            name: 'Rplan',
            key: 'rpan',
            label: 'Rplan#',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Brand',
            key: 'groupId',
            label: 'Brand',
            value: null,
            selectable: true,
            placeholderType: 'select',
            options: [
              { value: 'Rogers', text: 'Rogers' },
              { value: 'FIDO', text: 'FIDO' },
              { value: 'Rogers in FIDO', text: 'Rogers in FIDO' },
              { value: 'FIDO in Rogers', text: 'FIDO in Rogers' }
            ]
          },
          {
            name: 'Banner',
            key: 'banner',
            label: 'Banner',
            value: null,
            selectable: true,
            options: [
              { value: 'corporate', text: 'Corporate' },
              { value: 'Dealers', text: 'Dealers' }
            ],
            placeholderType: 'select'
          }
        ],
        // 2
        [
          {
            name: 'Address',
            key: 'address',
            label: 'Address',
            value: null,
            options: [],
            selectable: false,
            placeholderType: 'input',
            class: 'col-8 !important'
          },
          {
            name: 'Language',
            key: 'language',
            label: 'Language',
            value: null,
            options: [
              { value: 'En', text: 'En' },
              { value: 'FR', text: 'FR' },
              { value: 'BIL', text: 'BIL' }
            ],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Time zone',
            key: 'zoneId',
            label: 'Time zone',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select',
            disabled: true
          }
        ],
        // 3
        [
          {
            name: 'Country',
            key: 'country',
            label: 'Country',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Region',
            key: 'region',
            label: 'Region',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'City',
            key: 'city',
            label: 'City',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          }
        ],
        [
          // 4
          {
            name: 'Country',
            key: 'countryInput',
            label: 'Country',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Region',
            key: 'regionInput',
            label: 'Region',
            value: '',
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'City',
            key: 'cityInput',
            label: 'City',
            value: '',
            selectable: false,
            placeholderType: 'input'
          }
        ],
        // 5
        [
          // {
          //   name: 'Store Code',
          //   key: 'store',
          //   label: 'Store Code',
          //   value: null,
          //   selectable: false,
          //   placeholderType: 'input'
          // },

          {
            name: 'Time Server',
            key: 'ipNtpServer',
            label: 'Time Server',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Whitelist',
            key: 'whiteListEnable',
            label: 'Whitelist',
            value: 'Enabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          },
          {
            name: 'LBS',
            key: 'lbsEnabled',
            label: 'LBS',
            value: 'Disabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }
        ]

      ],
      advStoreInputs: [
        [
          {
            name: 'Lost Time',
            key: 'lostTime',
            label: 'Lost Time',
            value: '0',
            defaultValue: '0',
            creteria: 'Hour',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Logo Display Time',
            key: 'logoDisplayTime',
            label: 'Logo Display Time',
            value: '0',
            defaultValue: '0',
            creteria: 'Hour',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Beacon Loss Limit',
            key: 'beaconLossLimit',
            label: 'Beacon Loss Limit',
            value: '10',
            creteria: 'Count',
            defaultValue: '10',
            selectable: false,
            placeholderType: 'range'
          }
        ],
        [
          {
            name: 'Alive Check Interval',
            key: 'aliveInterval',
            label: 'Alive Check Interval',
            value: '24',
            creteria: 'Hour',
            defaultValue: '24',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Reactivation Retry Limit',
            key: 'reactivationRetryLimit',
            label: 'Reactivation Retry Limit',
            value: '2',
            creteria: 'Count',
            defaultValue: '2',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Scan Period Set',
            key: 'scanPeriodSet',
            label: 'Scan Period Set',
            value: '0',
            creteria: 'Count',
            defaultValue: '0',
            selectable: false,
            placeholderType: 'range'
          }
        ],
        [
          {
            name: 'Icon Display Mode',
            key: 'batteryMode',
            label: 'Icon Display Mode',
            value: '0',
            defaultValue: 0,
            options: [0, 1, 2, 3, 4, 5, 6],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Icon Display Position',
            key: 'location',
            label: 'Icon Display Position',
            value: 'Right-Bottom',
            defaultValue: '',
            options: ['Right-Bottom', 'Right-Top', 'Left-Top', 'Left-Bottom'],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Update Page Unlock',
            key: 'updatePageUnlock',
            label: 'Update Page Unlock',
            value: 'Disabled',
            defaultValue: 'Disabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }
        ],
        [
          // 3. ※ Hidden inputs ※
          {
            name: 'AES',
            key: 'aesEnable',
            label: 'AES',
            value: 'Disabled',
            defaultValue: '',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }

        ],
        [
          {
            name: 'Tap&Go Start Time',
            key: 'tapGoStartTime',
            label: 'Tap&Go Start Time',
            value: '0',
            defaultValue: '',
            selectable: false,
            placeholderType: 'own',
            placeholder: 'Range: 0 ~ 23',
            min: 0,
            max: 25,
            disabled: this.getTapAndGoDisabled()
          },
          {
            name: 'Tap&Go End Time',
            key: 'tapGoEndTime',
            label: 'Tap&Go End Time',
            value: '1',
            defaultValue: '',
            selectable: false,
            placeholderType: 'own',
            placeholder: 'Range: 0 ~ 23',
            min: 0,
            max: 25,
            disabled: this.getTapAndGoDisabled()
          },
          {
            name: 'Flexible Default',
            key: 'flexibleDefault',
            label: 'Flexible Default',
            value: '0',
            defaultValue: '0~23',
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'ACS',
            key: 'acsEnable',
            label: 'ACS',
            value: 'Disabled',
            defaultValue: '',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          },
          {
            name: 'Page Back Time',
            key: 'pageBackTime',
            label: 'Page Back Time',
            value: '0',
            defaultValue: '0',
            options: [0, 10, 20, 30, 40, 50, 60],
            selectable: true,
            placeholderType: 'input'
          },
          {
            name: 'ChAlive',
            key: 'chAlive',
            label: 'ChAlive',
            value: '78',
            creteria: 'Count',
            defaultValue: '78',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'ChLbs',
            key: 'chLbs',
            label: 'ChLbs',
            value: '78',
            creteria: 'Count',
            defaultValue: '78',
            selectable: false,
            placeholderType: 'range',
            disabled: false
          }
        ]
      ],
      prevLbsEnable: false,
      keepStoreDescValue: '',

      // SSE
      notifications: false,
      picking: false,
      alarm: false,
      SSEEnabled: false,
      rabbitMqEnabled: '',
      sseUrl: ''

      // SSE END

    }
  },
  methods: {
    handleKeyUpspl (e) {
      commons.specialCharRest(e)
    },
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    cancelStoreSetting () {
      this.toggleEditStore()
      // this.$emit('fireGetStores')
      if (this.advancedSettingVisible === 0) {
        this.toggleAdvSetting()
      }
    },
    setConditionsforChLbs (key) {
      if (key.key === 'lbsEnabled') {
        if (key.value === 'Enabled') {
          this.advStoreInputs[4][6].disabled = false
        }
        if (key.value === 'Disabled') {
          this.advStoreInputs[4][6].disabled = true
        }
      }
    },
    toggleEditStore () {
      this.storeSettingVisible = !this.storeSettingVisible
    },
    toggleAdvSetting () {
      this.advancedSettingVisible = this.advancedSettingVisible === 0 ? 1 : 0
    },
    getPlaceholder (input) {
      let placeholder = ''
      switch (input.placeholderType) {
        case 'select':
          placeholder = this.$t(`Select ${input.name.toLowerCase()}`)
          break
        case 'toggle':
          placeholder = this.$t('Enable/Disable')
          break
        case 'input':
          placeholder = this.$t(`Input ${input.name.toLowerCase()}`)
          break
        case 'own':
          placeholder = this.$t(input.placeholder)
          break
        case 'range':
          placeholder = `${this.$t('Criteria')}:${input.creteria}, ${this.$t('Default Value')}:${input.defaultValue}`
          break
        default:
          placeholder = this.$t(`Input ${input.name}`)
          break
      }
      return placeholder
    },
    buildEditStoreReqBody (storeInputs, advStoreInputs) {
      const reqBody = {}
      const storeConfig = {}
      storeInputs = storeInputs.flat()
      advStoreInputs = advStoreInputs.flat()
      storeInputs = storeInputs.map((input) => {
        if (input.placeholderType === 'toggle') {
          if (!commons.isValidStr(input.value)) return input
          input.value = this.enableToTrue(input.value)
        }
        if (this.isArea(input.key) && this.isAddArea(input.value)) {
          // 입력정보가 지역정보(Country, Region, City)이고, 값이 default 값인 경우.
          input.value = null
        }
        return input
      })
      advStoreInputs = advStoreInputs.map((input) => {
        if (input.placeholderType === 'toggle') { input.value = this.enableToTrue(input.value) }
        return input
      })
      // area 정보 제외 http 요청 body에 담음
      var data = []
      for (const input of storeInputs) {
        if (this.isAreaInput(input.key)) continue
        if (this.CUSTOMER === 'ROGERS') {
          if (input.key === 'province') {
            data.push({ province: input.value })
          }
          if (input.key === 'groupId') {
            data.push({ groupId: input.value })
          }
          if (input.key === 'banner') {
            data.push({ banner: input.value })
          }
          if (input.key === 'language') {
            data.push({ language: input.value })
          }
          if (input.key === 'company') {
            data.push({ company: input.value })
          }
          if (input.key === 'rpan') {
            data.push({ rPan: input.value })
          }
          if (input.key === 'address') {
            data.push({ address: input.value })
          }
          const mergedObject = data.reduce((result, current) => {
            return { ...result, ...current }
          }, {})
          this.keepStoreDescValue = JSON.stringify(mergedObject)
        }
        if (input.key === 'address') {
          reqBody.storeDescription = { value: input.value, name: 'Address' }
        } else {
          reqBody[input.key] = { value: input.value, name: input.name }
        }
      }
      if (this.CUSTOMER === 'ROGERS') {
        if (
          reqBody.groupId === 'Rogers' ||
           reqBody.groupId === 'Rogers in Fido'
        ) {
          reqBody.siteCode = '1234'
        } else {
          reqBody.siteCode = 'F1D0'
        }
      }
      for (const input of advStoreInputs) {
        storeConfig[input.key] = { value: input.value, name: input.name }
      }
      storeConfig.location.value = this.getLocationCode(
        storeConfig.location.value
      )
      reqBody.storeConfig = storeConfig
      return reqBody
    },

    async handleSaveBtnClick () {
      var accessmenu = this.$store.state.auth.user.accountInfo.accessMenu
      let lbsConfigured = true
      if (this.user.lbsEnable === 'true') {
        // 접속한 유저가 lbs 사용가능한 유저일때만 lbs settings도 추가한다.
        let lbsEnabled
        if (this.CUSTOMER === 'ROGERS') {
          lbsEnabled =
           this.enableToTrue(this.newStoreInputs[5][2].value) || false
        } else {
          lbsEnabled =
           this.enableToTrue(this.storeInputs[4][2].value) || false
        }

        if (this.prevLbsEnable !== lbsEnabled) {
          if (accessmenu.indexOf('6100') !== -1) {
            lbsConfigured = await this.setLbsConfiguration(lbsEnabled)
          }
        }
      }
      if (lbsConfigured === true) {
        // LBS 설정 성공하거나 User가 LBS설정 불가능한 경우.
        this.editStore()
        this.$store.dispatch(
          'dataStore/UPDATE_SELECTED_STORE',
          this.$store.getters['dataStore/GET_SELECTED_STORE']
        )
      }
    },
    async editStore () {
      if (this.CUSTOMER === 'ROGERS') {
        var reqBody1 = await this.buildEditStoreReqBody(
          commons.copy(this.newStoreInputs),
          commons.copy(this.advStoreInputs)
        )
      } else {
        var reqBody2 = await this.buildEditStoreReqBody(
          commons.copy(this.storeInputs),
          commons.copy(this.advStoreInputs)
        )
      }
      let reqBody = this.CUSTOMER === 'ROGERS' ? reqBody1 : reqBody2
      const isStoreConfigValid = await this.isStoreConfigValid(
        commons.copy(reqBody.storeConfig)
      )
      const storeConfig = this.mapToValues(reqBody.storeConfig)
      delete reqBody.storeConfig
      const siteCode = reqBody.siteCode
      delete reqBody.siteCode
      if (this.CUSTOMER !== 'ROGERS') {
        var descriptionValue = reqBody.storeDescription
        delete reqBody.storeDescription
      }
      const isBodyValid = await this.isObjValid(reqBody)
      if (!isBodyValid || !isStoreConfigValid) return
      if (this.CUSTOMER !== 'ROGERS') {
        reqBody.storeDescription = descriptionValue
      }
      reqBody = this.mapToValues(reqBody)
      reqBody.storeConfig = storeConfig
      if (this.CUSTOMER === 'ROGERS') {
        reqBody.siteCode = siteCode
        reqBody.province = reqBody.region
        reqBody.storeDescription = this.keepStoreDescValue
      }

      // SSE Config
      var arr = []
      if (this.alarm === true) {
        arr.push('ALARM_STATUS')
      }
      if (this.picking === true) {
        arr.push('PICKING_STATUS')
      }
      if (arr.length !== 0) {
        reqBody.serverSentEventTypes = arr
      } else {
        reqBody.serverSentEventTypes = null
      }
      // END SSE

      // if (
      //   reqBody.ipNtpServer.match(
      //     /^(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)$/
      //   )
      // ) {
      //   console.log('Enter valid Server IP')
      // } else {
      //   EventBus.$emit('messageAlert', 'Enter valid Server IP')
      //   return
      // }
      const config = { params: { store: this.storeInputs[3][1].value } }
      this.$utils
        .callAxiosWithBody(
          codes.requests.editStore.method,
          codes.requests.editStore.url,
          reqBody,
          config
        )
        .then((res) => {
          // Refresh ManagedStores
          EventBus.$emit('setManagedStores')
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.showStoreSettingData()
          this.cancelStoreSetting()
          this.$emit('fireGetStores')
          const selectedStore = this.$store.state.dataStore.selectedStore
          if (reqBody.store === selectedStore.code && (reqBody.storeName !== selectedStore.name || reqBody.country !== selectedStore.country || reqBody.region !== selectedStore.region || reqBody.city !== selectedStore.city)) {
            selectedStore.country = reqBody.country
            selectedStore.region = reqBody.region
            selectedStore.name = reqBody.storeName
            selectedStore.city = reqBody.city
            this.$store.dispatch('dataStore/UPDATE_SELECTED_STORE', selectedStore)
          }
        })
        .catch(error => {
          if (error.response.data.responseCode === '503') {
            EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to edit store.'))
          }
        })
    },
    getStoreInfo (storeCode) {
      const params = { store: storeCode }
      const config = { params }
      // cloud 용 임시
      if (this.productionType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }
      return this.$utils
        .callAxios(
          codes.requests.getStoreInfo.method,
          codes.requests.getStoreInfo.url,
          config
        )
        .then((res) => {
          return res.data
        })
    },
    isAddBtn (val, idx) {
      if (Object.values(codes.msg.areaSelection)[idx] === val) return true
      return false
    },
    getStoreInputTextClass (inputKey, idx) {
      let idxStr = ''
      let cls = 'form-input'
      if (this.isAreaInput(inputKey)) {
        cls = 'form-input in-btn'
        if (this.CUSTOMER !== 'ROGERS') {
          // console.log('insideeeeeeeeeeeeee', this.storeInputs[idx])
          if (
            !this.isAddBtn(
              this.storeInputs[idx - 1][
                inputKey === 'regionInput'
                  ? 1
                  : inputKey === 'cityInput'
                    ? 2
                    : 0
              ].value,
              inputKey === 'regionInput' ? 1 : inputKey === 'cityInput' ? 2 : 0
            )
          ) {
            // select 값이 있으면 hidden
            cls = 'form-input in-btn hidden'
          }
        } else {
          if (inputKey === 'countryInput') {
            idxStr = '01'
          } else if (inputKey === 'regionInput') {
            idxStr = '12'
          } else {
            idxStr = '23'
          }
          if (!this.isAddBtn(this.newStoreInputs[idx - 1][idxStr[0]].value, idxStr[1] - 1)) {
            cls = 'form-input in-btn hidden'
          }
        }
      }
      return cls
    },
    getSwitchInputValue (storeInfoVal, storeInput) {
      return storeInfoVal ? storeInput.options[0] : storeInput.options[1]
    },
    toInputValue (storeInfoVal, storeInput) {
      let inputValue = null
      switch (storeInput.key) {
        case codes.configKeys.whiteListEnable:
          inputValue = this.getSwitchInputValue(storeInfoVal, storeInput)
          break
        case codes.configKeys.lbsEnabled:
          inputValue = this.getSwitchInputValue(storeInfoVal, storeInput)
          break
        case codes.configKeys.updatePageUnlock:
          inputValue = this.getSwitchInputValue(storeInfoVal, storeInput)
          break
        case codes.configKeys.location:
          inputValue = storeInput.options[storeInfoVal]
          break
      }
      return inputValue
    },
    toSetSSEURL (url) {
      if (url.endsWith(':')) {
        return url.slice(0, -1)
      } else {
        return url
      }
    },
    setStoreInputs (store) {
      console.log(store)
      var serverDetail = this.toSetSSEURL(configs.ServerAddress)
      this.sseUrl = serverDetail + '/link/core/sse/' + store.store
      console.log(this.sseUrl)
      // SSE Config
      if (store.serverSentEventTypes) {
        if (store.serverSentEventTypes.length !== 0) {
          this.alarm = store.serverSentEventTypes.includes('ALARM_STATUS')
          this.picking = store.serverSentEventTypes.includes('PICKING_STATUS')
        } else {
          store.serverSentEventTypes = null
        }
      }
      // END SSE
      // this.store -> userInfo에서 불러온 store 정보
      // store -> store/detail 요청으로 불러온 store 정보
      // this.store는 areaGroupManagememt에서 수정한 지역정보가 반영된다.
      this.prevLbsEnable = store.lbsEnabled
      var storeKey = Object.keys(store)
      var storeAdvanceKey = Object.keys(store.storeConfig)
      var rogerFields
      var rogerFieldsKey
      var concatkey

      this.storeInputs.forEach((n, i) => {
        n.forEach((b, j) => {
          storeKey.filter((item) => {
            if (item === b.key) {
              if (item === 'whiteListEnable') {
                this.storeInputs[i][j].value = this.toInputValue(
                  store.whiteListEnable, this.storeInputs[i][j])
              } else if (item === 'lbsEnabled') {
                this.storeInputs[i][j].value = this.toInputValue(
                  store.lbsEnabled, this.storeInputs[i][j])
              } else if (item === 'company') {
                this.storeInputs[i][j].options.push(this.store.company)
                this.storeInputs[i][j].value = this.store.company
              } else if (item === 'region') {
                this.storeInputs[i][j].options.push(this.store.region)
                this.storeInputs[i][j].value = store.region
              } else if (item === 'city') {
                this.storeInputs[i][j].options.push(this.store.city)
                this.storeInputs[i][j].value = store.city
              } else {
                this.storeInputs[i][j].value = store[item]
              }
            }
          })
        })
      })
      if (this.CUSTOMER === 'ROGERS') {
        rogerFieldsKey = Object.keys(JSON.parse(store.storeDescription))
        rogerFields = JSON.parse(store.storeDescription)
        concatkey = [...storeKey, ...rogerFieldsKey]

        this.newStoreInputs.forEach((r, i) => {
          r.forEach((s, j) => {
            concatkey.filter((item) => {
              if (item === 'rPan') {
                item = 'rpan'
              }
              if (item === s.key) {
                if (item === 'whiteListEnable') {
                  this.newStoreInputs[i][j].value = this.toInputValue(
                    store.whiteListEnable, this.newStoreInputs[i][j])
                } else if (item === 'lbsEnabled') {
                  this.newStoreInputs[i][j].value = this.toInputValue(
                    store.lbsEnabled, this.newStoreInputs[i][j])
                } else if (item === 'company') {
                  this.newStoreInputs[i][j].options.push(this.store.company)
                  this.newStoreInputs[i][j].value = store.company
                } else if (item === 'region') {
                  this.newStoreInputs[i][j].options.push(this.store.region)
                  this.newStoreInputs[i][j].value = store.region
                } else if (item === 'city') {
                  this.newStoreInputs[i][j].options.push(this.store.city)
                  this.newStoreInputs[i][j].value = store.city
                } else {
                  if (item === 'rpan') {
                    item = 'rPan'
                    this.newStoreInputs[i][j].value = rogerFields[item]
                  } else if (item === 'address' || item === 'banner' || item === 'groupId' || item === 'province' || item === 'language') {
                    this.newStoreInputs[i][j].value = rogerFields[item]
                  } else {
                    this.newStoreInputs[i][j].value = store[item]
                  }
                }
              }
            })
          })
        })
      }
      this.advStoreInputs.forEach((m, i) => {
        m.forEach((c, j) => {
          storeAdvanceKey.filter((item) => {
            if (item === c.key) {
              console.log(item + '===' + c.key)

              if (item === 'location') {
                this.advStoreInputs[i][j].value = this.toInputValue(
                  store.storeConfig.location, this.advStoreInputs[i][j])
              } else if (item === 'updatePageUnlock') {
                this.advStoreInputs[i][j].value = this.toInputValue(
                  store.storeConfig.updatePageUnlock, this.advStoreInputs[i][j])
              } else if (item === 'acsEnable') {
                if (store.storeConfig.acsEnable === true) {
                  this.advStoreInputs[i][j].value = 'Enabled'
                } else {
                  this.advStoreInputs[i][j].value = 'Disabled'
                }
              } else if (item === 'lbsEnabled') {
                if (store.lbsEnabled === false) {
                  this.advStoreInputs[i][j].disabled = true
                } else if (store.lbsEnabled === true) {
                  this.advStoreInputs[i][j].disabled = false
                }
              } else {
                this.advStoreInputs[i][j].value = store.storeConfig[item]
              }
            }
          })
        })
      })

      // Region
      this.storeInputs[2][0].options = this.getAreaNames(
        this.countries[this.store.country],
        1
      )
      const spaceforregion = ''
      const arrforregion = this.storeInputs[2][0].options.filter(item => !spaceforregion.includes(item))
      this.storeInputs[2][0].options = arrforregion
      /// end
      // City

      this.storeInputs[3][0].options = this.getAreaNames(
        this.countries[this.store.country][this.store.region],
        2
      )
      const spaceforcity = ''
      const arrforcity = this.storeInputs[3][0].options.filter(item => !spaceforcity.includes(item))
      this.storeInputs[3][0].options = arrforcity

      // end
    },
    async showStoreSettingData () {
      this.rabbitMqEnabled = this.$store.state.auth.user.rabbitMqEnable
      // SSE
      // if (this.rabbitMqEnabled === 'false') {
      this.togetSSEConfig()
      // }
      // END
      await this.initiateStorePopup()
      this.getStoreInfo(this.store.store).then((data) => {
        this.setStoreInputs(data)
      })
    },
    async initiateStorePopup () {
      await this.refreshAreas()
      this.storeInputs[3][0].options = this.timezones
    },
    // SSE Configuration
    togetSSEConfig () {
      const url = '/api/common/config/SSE'
      const config = { params: {} }
      this.$utils.callAxios(
        'get',
        url, config).then((res) => {
        if (res.data) {
          console.log(res.data)
          this.notifications = res.data.enabled === 'true' || res.data.enabled === true
        }
      })
    }
  },
  mounted () {
    // SingleUser SINGLE 이면 area 정보 disabled
    // this.initiateStorePopup()
  }
}
</script>

 <style scoped>
 .SingleUser {
   display: none;
 }
 .hidden {
   display: none;
 }
 </style>
