<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-store"></i>{{ $t("Store Info") }}
    </h2>
    <!-- 검색창 -->

    <!-- Search area -->
    <!-- Text-based search inputs -->
    <div class="searchArea" style="margin-top: 45px !important;" v-if="CUSTOMER != 'ROGERS'">
      <v-text-field v-for="searchInput of searchInputs.text" :key="searchInput.name" v-model="searchInput.value"
        @keyup.enter="searchStores()" :label="$t(searchInput.label)" :placeholder="$t(getPlaceholder(searchInput))"
        outlined dense hide-details class="form-input ml-3" clearable></v-text-field>
      <!-- Select-based search inputs -->
      <v-select v-for="(searchInput, idx) of searchInputs.select" :key="searchInput.name" v-model="searchInput.value"
        @change="setSelectableAreaNames(idx)" :label="$t(searchInput.label)"
        :placeholder="$t(getPlaceholder(searchInput))" :items="searchInput.options"
        :no-data-text="$t('No data available')" class="form-select ml-3" outlined dense hide-details clearable></v-select>
      <!-- Search button -->
      <v-btn @click="searchStores()" class="btn type-search ml-3" text>{{ $t("Search") }}
      </v-btn>
    </div>
    <div class="col-12 pl-0 pb-0 d-flex" v-if="CUSTOMER === 'ROGERS'">
      <div style="margin-left: 12px;margin-bottom: 5px;" class="col-3 pl-0 pb-0 mt-2 justify-content-between">
        <v-btn @click="clickFilter()" class="filter_button mt-2" style="justify-content: space-between !important;">{{
          $t('Filter Clear') }}
          <v-icon right dark>mdi-cached</v-icon>
        </v-btn>
      </div>
      <div class="col-6 pt-3 mt-5" > {{searchInput.region}} {{searchInput.city}}{{searchInput.country}}</div>
      <div class="col-3 searchArea">

      </div>
      </div>
    <!-- Store List -->
    <div class="col-12 d-flex pt-0">
        <!-- Side Filter for Rogers -->
      <div class="col-3 pl-0 pt-0"  v-if="CUSTOMER === 'ROGERS'">
          <v-select
        v-model="selectedHeaders"
        :items="defaultEmptyHeader"
        placeholder="Column setting"
        return-object multiple dense
        class="columnDropdown mb-4">
          <template v-slot:selection="{ index }">
              <div v-if="index < 1">
                <span style="color:#ffff;">Column setting</span>
              </div>
              <span v-if="index === 2" class="grey--text caption"></span>
            </template>
            <template v-slot:prepend-item>

            <v-list-item
             title="Select All"
             @click="toggle()">
             <v-list-item-action>
                <v-icon :color="selectedHeaders.length > 0 ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  All
                </v-list-item-title>
              </v-list-item-content>
               <template v-slot:prepend>
              <v-checkbox-btn
                :color="filterCheckbox ? 'indigo-darken-4' : undefined"
                :indeterminate="filterCheckbox && !headerCheckbox"
                :model-value="filterCheckbox"
              ></v-checkbox-btn>
            </template>

            </v-list-item>
          </template>
        </v-select>

         <div class="filters mt-2">
          <div class="d-flex mt-2" style="padding-bottom: 2px;justify-content:space-between;">
            <div class="pl-1">Country</div>
            <v-icon v-if="isCountryShow" @click="isCountry()">mdi-chevron-up</v-icon>
            <v-icon v-else @click="isCountry()">mdi-chevron-down</v-icon>
          </div>
          <div class="d-flex col-12 ml-0 p-0 row justify-content-between filter_text"
            style="align-items: flex-start;padding:0px !important; margin-top:0px !important;padding-bottom:12px !important" v-if="isCountryShow">
            <div class="col-10" style="padding:0px;text-align: right;margin-top: 5px !important;">
              <div v-for="i in typeCountry" :key="i" style="height:25px;text-overflow: ellipsis">
                <v-checkbox style="margin-bottom:15px;text-overflow: ellipsis;white-space: nowrap;
                  overflow: hidden;" v-model="searchInput.country" :label="`${i}`" :value="i" @change="searchStores()"></v-checkbox>
              </div>
            </div>
            <div class="col-2" style="padding:4px;">

            </div>
          </div>
          <div class="d-flex mt-2" style="padding-bottom: 2px;justify-content:space-between;">
            <div class="pl-1">Region</div>
            <v-icon v-if="isRegionShow" @click="isRegion()">mdi-chevron-up</v-icon>
            <v-icon v-else @click="isRegion()">mdi-chevron-down</v-icon>
          </div>
          <div class="d-flex col-12 ml-0 p-0 row justify-content-between filter_text"
            style="align-items: flex-start;padding:0px !important; margin-top:0px !important;padding-bottom:12px !important" v-if="isRegionShow">
            <div class="col-10" style="padding:0px;text-align: right;margin-top: 5px !important;">
              <div v-for="i in typeRegion" :key="i" style="height:25px;text-overflow: ellipsis">
                <v-checkbox style="margin-bottom:15px;text-overflow: ellipsis;white-space: nowrap;
                  overflow: hidden;" v-model="searchInput.region" :label="`${i}`" :value="i" @change="searchStores()"></v-checkbox>
              </div>
            </div>
            <div class="col-2" style="padding:4px;">

            </div>
          </div>
          <div class="d-flex mt-2" style="padding-bottom: 2px;justify-content:space-between;">
            <div class="pl-1">City</div>
            <v-icon v-if="isCityShow" @click="isCity()">mdi-chevron-up</v-icon>
            <v-icon v-else @click="isCity()">mdi-chevron-down</v-icon>
          </div>
          <div class="d-flex col-12 ml-0 p-0 row justify-content-between filter_text"
            style="align-items: flex-start;padding:0px !important; margin-top:0px !important;padding-bottom:12px !important" v-if="isCityShow">
            <div class="col-10" style="padding:0px;text-align: right;margin-top: 5px !important;">
              <div v-for="i in typeCity" :key="i" style="height:25px;text-overflow: ellipsis">
                <v-checkbox style="margin-bottom:15px;text-overflow: ellipsis;white-space: nowrap;
                  overflow: hidden;" v-model="searchInput.city" :label="`${i}`" :value="i" @change="searchStores()"></v-checkbox>
              </div>
            </div>
            <div class="col-2" style="padding:4px;">

            </div>
          </div>

        </div>
      </div>
      <!-- First data table -->
      <div class="col-4 pr-0  pt-0" :style="this.CUSTOMER==='ROGERS'?'padding: 0px 0px 12px 12px !important;margin-top: -40px;':''">
        <v-data-table v-model="selected" item-key="store" :headers="this.CUSTOMER==='ROGERS'?defaultHeaderRogers:storeHeader1" :items="tableItems"
          :hide-default-footer="true" :options.sync="options" :server-items-length="totalStores"
          :single-select="singleSelect" class="tbl-type01 mt-10" show-select>
          <template slot="no-data">
            <p>
              {{ $t("No data available") }}
            </p>
          </template>
          <template v-slot:[`item.storeName`]="{ item }">
            <popupSummary :user="user" :item="item" :buttonDisabled="popupDisabled"
              @fireGetStores="initiateStoreInfoPage(1)"></popupSummary>
          </template>
        </v-data-table>
      </div>
      <div :class="this.CUSTOMER==='ROGERS'?'col-5 pl-0 pt-0':'col-8 pl-0 pt-0'" :style="this.CUSTOMER==='ROGERS'?'margin-top: -40px;':''">
        <!--  Changes made for RELIANCE user -->
        <!-- Second data table -->
        <v-data-table v-model="selected" item-key="store" :headers="showHeaders" :items="tableItems"
          :hide-default-footer="true" :options.sync="options" :hide-default-header="this.CUSTOMER === 'RELIANCE'"
          :server-items-length="totalStores" :single-select="singleSelect" class="tbl-type01 mt-10 tblBorder">
          <template slot="no-data">
            <p>
              {{ $t("No data available") }}
            </p>
          </template>
          <!-- Custom headers and values for RELIANCE user -->
          <template v-if="this.CUSTOMER === 'RELIANCE'" #header="{}">
            <thead class="v-data-table-header">
              <tr>
                <!-- Header columns -->
                <th v-for="header in  computedHeaders " :key="header.value" @click="sortRelianceHeaders(header)"
                  :colspan="header.children ? header.children.length : 1" :rowspan="header.children ? 1 : 2"
                  :class="['text-center', 'bl-1', 'bt-1', options.sortDesc.length === 0 ? 'sort-icon-header' : '', header.sortable ? 'cursor-header' : '']">
                  {{ header.text }}
                  <v-icon v-if="header.sortable" :class="[
                    relianceSortDirection === undefined || options.sortBy[0] !== header.value
                      ? 'sort-deactive'
                      : 'sort-active',
                    'sort-icon'
                  ]">
                    {{
                      !relianceSortDirection || relianceSortDirection === undefined
                      ? "mdi-arrow-up"
                      : "mdi-arrow-down"
                    }}</v-icon>
                </th>
              </tr>
              <tr>
                <!-- Child headers -->
                <th v-for=" childHeader  in  getChildHeaders() " :key="childHeader.value" class="bl-1 bt-1">
                  {{ childHeader.text }}
                </th>
              </tr>
            </thead>
          </template>
          <template #item="props" v-if="this.CUSTOMER === 'RELIANCE'">
            <tr>
              <!-- RELIANCE-specific table row -->
              <td class="text-center">{{ props.item.storeDescription }}</td>
              <td class="text-center">{{ props.item.country }}</td>
              <td class="text-center">{{ props.item.region }}</td>
              <td class="text-center">{{ props.item.city }}</td>
              <td class="text-center" v-for=" subHeaderValue  in  props.headers[4].children " :key="subHeaderValue.value">
                {{ props.item[subHeaderValue.value] }}
              </td>
              <td class="text-center" v-for=" subHeaderValue  in  props.headers[5].children " :key="subHeaderValue.value">
                {{ props.item[subHeaderValue.value] }}
              </td>
              <td class="text-center">
                <store-settings-modal :user="user" :store="props.item" :countries="areas" :timezones="timezones"
                  :buttonDisabled="buttonDisabled" :areaAddDisabled="areaAddDisabled"
                  @fireGetStores="initiateStoreInfoPage(page)" />
              </td>
            </tr>
          </template>
          <!--  Changes made for RELIANCE user -->
          <template v-if="this.CUSTOMER !== 'RELIANCE'" v-slot:[`item.setting`]="{ item }">
            <store-settings-modal :user="user" :store="item" :countries="areas" :timezones="timezones"
              :buttonDisabled="buttonDisabled" :areaAddDisabled="areaAddDisabled"
              @fireGetStores="initiateStoreInfoPage(page)" />
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- Button group -->
    <div class="table-options">
      <div>
        <popupAddSummary :user="user" :buttonDisabled="buttonDisabled" :areaAddDisabled="areaAddDisabled"
          @fireGetStores="initiateStoreInfoPage(1)"></popupAddSummary>
        <v-btn :class="{ hidden: SingleUser }" :disabled="btnDisabledDelete" @click="deleteStores()" class="btn ml-2"
          text>{{ $t("Delete") }}
        </v-btn>
        <v-btn @click="exportStores()" :disabled="exportDisabled" :class="{ hidden: SingleUser }" class="btn ml-2" text>{{
          $t("Export") }}
        </v-btn>
      </div>
      <!-- Pagination -->
      <!-- <div class="pageInfo">{{ pageInfoText }}</div> -->

      <!-- <div>
        <v-pagination
          v-model="page"
          @input="paging"
          :length="totalPages * 1"
          :total-visible="7"
          color="#2f3b4c"
          class="pageAlign"
        ></v-pagination>
      </div> -->
    </div>
    <div class="col-12 pr-0" style="padding:0px">
      <Pagination @paging="paging($event)" :pageInfoText="pageInfoText" :pageIndex="pageIndex" :rowPerPage="rowPerPage"
        :goToPageInput="goToPageInput" :totalPages="totalPages" @updatePage="updatePage($event)"></Pagination>
    </div>
    <a ref="link" :style="{ display: 'none' }" />
  </div>
</template>

<script>
import popupAddSummary from './modal/StorePopupAddSummary'
import popupSummary from './modal/StorePopupSummary'
import storeSettingsModal from './modal/StoreSettingsModal'
import { exportFiles } from '@/plugins/exporter'
import { setTempObj, getTempObj } from '@/plugins/sessionStorageManager'

import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import Pagenation from '@/mixins/Pagenation'
import Pagination from '@/components/pagination/Pagination.vue'
import '@/assets/css/freeze.css'
import configs from '@/plugins/configs'

const msg = {
  areaSelection: {
    addCountry: 'Add Country.',
    addRegion: 'Add Region.',
    addCity: 'Add City.'
  }
}

export default {
  name: 'StoreInfo',
  mixins: [Pagenation],
  components: {
    popupSummary,
    popupAddSummary,
    storeSettingsModal,
    Pagination
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      user: null,
      areas: [],
      countries: [],
      timezones: [],
      page: 1,
      rowPerPage: '10',
      goToPageInput: 1,
      pageIndex: 1,
      totalPages: 0,
      totalVisiblePages: 5,
      itemsPerPage: 10,
      pageInfoText: '',
      totalStores: null,
      relianceSortDirection: undefined,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      },
      selected: [],
      SingleUser: true,
      singleSelect: false,
      search: '',
      popupDisabled: false,
      buttonDisabled: false,
      areaAddDisabled: false,
      CUSTOMER: configs.Customer,

      requestConfig: {
        url: '',
        method: '',
        params: {
          sort: null
        }
      },
      // Search inputs configuration
      searchInputs: {
        text: [
          {
            name: 'Store Code',
            label: 'Store Code',
            key: 'store',
            value: '',
            placeholderType: 'input'
          },
          {
            name: 'Store Name',
            label: 'Store Name',
            key: 'name',
            value: '',
            placeholderType: 'input'
          }
        ],
        select: [
          {
            name: 'Country',
            key: 'country',
            value: '',
            options: [],
            placeholderType: 'select'
          },
          {
            name: 'Region',
            key: 'region',
            value: '',
            options: [],
            placeholderType: 'select'
          },
          {
            name: 'City',
            key: 'city',
            value: '',
            options: [],
            placeholderType: 'select'
          }
        ]
      },
      tableItems: [],
      storeCode: '',
      exportDisabledByAuth: false,
      // rogers input
      searchInput: {
        brand: null,
        country: null,
        region: null,
        city: null,
        timeZoneCheckbox: null,
        serverShow: null
      },
      // new changes
      selectedHeaders: [],
      defaultEmptyHeader: [],
      isBrandShow: false,
      isCountryShow: false,
      isRegionShow: false,
      isCityShow: false,
      isServerShow: false,
      isTimeZoneShow: false,
      typeCountry: [],
      typeRegion: [],
      typeRegions: [],
      typeCountries: [],
      typeCity: [],
      typeCitys: [],
      searchParam: {
        country: null,
        region: null,
        city: null
      }
    }
  },
  computed: {
    // Computed property for the first data table headers
    storeHeader1 () {
      return [
        { text: this.$t('STORE CODE'), value: 'store', width: '12%' },
        { text: this.$t('STORE NAME'), value: 'storeName' }
      ]
    },
    // Computed property for the second data table headers

    // Fixed Headers for Rogers
    defaultHeaderRogers () {
      return [
        { text: this.$t('MARKET LANDSCAPE ID'), value: 'store' },
        { text: this.$t('STORE NAME'), value: 'storeName' }
      ]
    },
    storeHeaders () {
      return [
        // { text: this.$t('STORE CODE'), value: 'store', width: '12%' },
        // { text: this.$t('STORE NAME'), value: 'storeName' },
        { text: this.$t('STORE DESCRIPTION'), value: 'storeDescription' },
        { text: this.$t('COUNTRY'), value: 'country', width: '10%' },
        { text: this.$t('REGION'), value: 'region', width: '10%' },
        { text: this.$t('CITY'), value: 'city', width: '10%' },
        {
          text: this.$t('LABEL'),
          value: 'labelCount',
          sortable: false,
          width: '10%'
        },
        {
          text: this.$t('GATEWAY'),
          value: 'gatewayCount',
          sortable: false,
          width: '10%'
        },
        { text: this.$t('RPLAN'), value: 'rPan', sortable: false },
        { text: this.$t('BRAND'), value: 'groupId', sortable: false },
        { text: this.$t('BANNER'), value: 'banner', sortable: false },
        { text: this.$t('LANGUAGE'), value: 'language', width: '5%', sortable: false },
        { text: this.$t('ADDRESS'), value: 'address', sortable: false },
        {
          text: this.$t('SETTINGS'),
          align: 'center',
          value: 'setting',
          sortable: false,
          width: '10%'
        }
      ]
    },
    computedHeaders () {
      // if (this.CUSTOMER !== 'ROGERS') {
      //   return this.storeHeaders.filter(
      //     item =>
      //       item.text !== 'RPLAN' &&
      //       item.text !== 'BRAND' &&
      //       item.text !== 'BANNER' &&
      //       item.text !== 'LANGUAGE' &&
      //       item.text !== 'ADDRESS'
      //   )
      // } else {
      //   return this.storeHeaders.filter(
      //     item => item.text !== 'STORE DESCRIPTION'
      //   )
      // }
      // Changes made for RELIANCE user
      // Customized headers for the second data table based on user type
      if (this.CUSTOMER === 'ROGERS') {
        return this.storeHeaders.filter(
          item => item.text !== 'STORE DESCRIPTION'
        )
      } else if (this.CUSTOMER === 'RELIANCE') {
        return this.storeHeaders
          .map(item =>
            item.text === 'LABEL'
              ? {
                ...this.storeHeaders[5],
                children: [
                  {
                    text: this.$t('Total'),
                    value: 'totalGwCount'
                  },
                  {
                    text: this.$t('Online'),
                    value: 'onlineGwCount'
                  },
                  {
                    text: this.$t('Offline'),
                    value: 'offlineGwCount'
                  }
                ]
              }
              : item.text === 'GATEWAY'
                ? {
                  ...this.storeHeaders[4],
                  children: [
                    {
                      text: this.$t('Total'),
                      value: 'totalUpdatedLabelCount'
                    },
                    {
                      text: this.$t('Success'),
                      value: 'updatedLabelCount'
                    },
                    {
                      text: this.$t('Processing'),
                      value: 'inProgressLabelCount'
                    },
                    {
                      text: this.$t('Timeout'),
                      value: 'notUpdatedLabelCount'
                    },
                    {
                      text: this.$t('Offline'),
                      value: 'offlineLabelCount'
                    }
                  ]
                }
                : item.text !== 'SETTINGS' ? { ...item, sortable: true } : item
          )
          .filter(
            item =>
              item.text !== 'RPLAN' &&
              item.text !== 'BRAND' &&
              item.text !== 'BANNER' &&
              item.text !== 'LANGUAGE' &&
              item.text !== 'ADDRESS'
          )
      } else {
        return this.storeHeaders.filter(
          item =>
            item.text !== 'RPLAN' &&
            item.text !== 'BRAND' &&
            item.text !== 'BANNER' &&
            item.text !== 'LANGUAGE' &&
            item.text !== 'ADDRESS'
        )
      }
    },
    // side filter changes for rogers
    showHeaders () {
      if ((this.CUSTOMER !== 'ROGERS') && (this.selectedHeaders === 0)) {
        return console.log('no datataa')
      } else {
        return this.defaultEmptyHeader.filter(s => this.selectedHeaders.includes(s))
      }
    },
    headerCheckbox () {
      return this.selectedHeaders.length === this.defaultEmptyHeader.length
    },
    filterCheckbox () {
      return this.selectedHeaders.length > 0
    },
    icon () {
      // if (this.likesAllFruit) return 'mdi-close-box'
      if (this.filterCheckbox) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    // end
    btnDisabledDelete () {
      let btnDisabled = this.buttonDisabled
      if (!btnDisabled) {
        btnDisabled = this.selected.length < 1
      }
      return btnDisabled
    },
    exportDisabled () {
      return (this.tableItems !== undefined && this.tableItems.length < 1) || this.exportDisabledByAuth
    }
  },
  watch: {
    options: {
      handler () {
        this.options.itemsPerPage = Number(this.rowPerPage)
        this.options.page = this.pageIndex
        this.getStores(this.options.page)
        this.page = 1
      },
      deep: true
    },
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    },
    searchInputs: {
      handler (newSearchInputs) {
        const storeInfo = {
          searchInputs: newSearchInputs
        }
        setTempObj('storeInfo', storeInfo)
      },
      deep: true
    }
  },
  created () {
    this.user = this.$store.state.auth.user
    // rogers changes
    this.defaultEmptyHeader = (Object.values(this.computedHeaders))
    this.selectedHeaders = this.defaultEmptyHeader
    //
    this.SingleUser = this.user.serverType === 'SINGLE' //  || CENTRAL
    this.setAreas()
  },
  mounted () {
    // Changes made for RELIANCE user
    // increasing the height of the v-data-table headers
    if (this.CUSTOMER === 'RELIANCE') {
      document.querySelector('.v-data-table-header').style.height = '100px'
    }
    this.clearTabindex()
    //  Store Info : Detail Popup
    this.$store.dispatch('auth/getDisabledBtn', '7100').then(flag => {
      this.popupDisabled = flag
    })
    //  Store Info : Add / Delete / Setting
    this.$store.dispatch('auth/getDisabledBtn', '7101').then(flag => {
      this.buttonDisabled = flag
    })
    //  Store Info : Export
    this.$store.dispatch('auth/getDisabledBtn', '7102').then(flag => {
      this.exportDisabledByAuth = flag
    })
    //  Store Info : Export
    this.$store.dispatch('auth/getDisabledBtn', '7103').then(flag => {
      this.areaAddDisabled = flag
    })
    EventBus.$emit('enableSelectedStores', false)
    this.initiateStoreInfoPage(1)
  },
  methods: {
    // Changes made for RELIANCE user
    getChildHeaders () {
      const childHeaders = this.computedHeaders.reduce((acc, header) => {
        if (header.children) {
          acc.push(...header.children)
        }
        return acc
      }, [])
      return childHeaders
    },
    sortRelianceHeaders (header) {
      // Sort the headers for RELIANCE user based on the selected header
      // This method is triggered when a header is clicked in the second data table
      // It updates the sort order and applies sorting to the data table
      if (header.text === 'GATEWAY' || header.text === 'LABEL' || header.text === 'SETTINGS') { return }
      if (header.value !== this.options.sortBy[0]) { this.relianceSortDirection = undefined }
      this.relianceSortDirection =
        this.relianceSortDirection === undefined
          ? false
          : !this.relianceSortDirection
            ? true
            : undefined
      this.options = {
        ...this.options,
        sortBy: this.relianceSortDirection === undefined ? [] : [header.value],
        sortDesc: this.relianceSortDirection === undefined ? [] : [this.relianceSortDirection]
      }
    },
    // filter clear event for rogers
    clickFilter () {
      this.searchInput.region = null
      this.searchInput.city = null
      this.searchInput.country = null
      this.searchStores()
    },
    // rogers toggle
    toggle () {
      // this.$nextTick(() => {
      if (this.headerCheckbox) {
        this.selectedHeaders = []
      } else {
        this.selectedHeaders = this.defaultEmptyHeader.slice()
      }
      // })
    },
    // show country region city on side filter for rogers
    isCountry () {
      this.isCountryShow = !this.isCountryShow
    },
    isRegion () {
      this.isRegionShow = !this.isRegionShow
    },
    isCity () {
      this.isCityShow = !this.isCityShow
    },
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // getAreas () {
    //   const config = { params: { company: this.user.company } }
    //   return this.$utils
    //     .callAxios(
    //       codes.requests.getAreas.method,
    //       codes.requests.getAreas.url,
    //       config
    //     )
    //     .then(res => {
    //       return res.data.areaParamList
    //     })
    // },
    paging (input) {
      this.goToPageInput = input
      this.getStores(input)
    },
    updatePage (event) {
      this.rowPerPage = event.toString()
      this.paging(1)
    },
    searchStores () {
      // Perform search based on input values
      // This method is triggered when the search button is clicked or enter key is pressed
      // It retrieves the search input values and performs a search operation
      this.clearSearchParams()
      this.searchParam = this.buildSearchParams(this.searchParam)
      this.getStores(1)
    },
    validate (val) {
      return val !== null && val !== undefined && val.length > 0
    },
    clearSearchParams () {
      if (this.searchInput.city === '' || this.searchInput.city === null) {
        this.searchParam.city = null
      }
      if (this.searchInput.region === '' || this.searchInput.region === null) {
        this.searchParam.region = null
      }
      if (this.searchInput.country === '' || this.searchInput.country === null) {
        this.searchParam.country = null
      }
      const inputs = [...this.searchInputs.text, ...this.searchInputs.select]
      for (const input of inputs) {
        if (!this.validate(input.value) && input.key === 'name') {
          this.searchParam.storeName = null
          continue
        }
        if (!this.validate(input.value) || input.value === ' ') {
          if (this.searchParam[input.key]) { this.searchParam[input.key] = null }
        }
      }
    },
    // Method to build the search parameters based on the input values
    buildSearchParams (params) {
      const inputs = [...this.searchInputs.text, ...this.searchInputs.select]
      for (const input of inputs) {
        if (this.validate(input.value) && input.key === 'name') {
          params.storeName = input.value
          continue
        }
        if (this.validate(input.value) && input.value !== ' ') {
          params[input.key] = input.value
        }
      }
      if (this.CUSTOMER === 'ROGERS') {
        params.city = this.searchInput.city
        params.region = this.searchInput.region
        params.country = this.searchInput.country
      }
      return params
    },
    buildParmas: function () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      // let params = { account: this.user.account, company: this.user.company, city: this.searchInput.city, region: this.searchInput.region, country: this.searchInput.country }
      let params = { account: this.user.account, company: this.user.company }
      // params = this.buildSearchParams(params)
      params = { ...params, ...this.searchParam }
      let sort = null
      if (sortBy.length === 1 && sortDesc.length === 1) {
        var sortKey = sortBy[0]
        if (sortKey === 'storeDescription') {
          sortKey = 'description'
        }
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = this.rowPerPage
      return params
    },
    getStores (page) {
      const params = this.buildParmas()
      if (page !== null) params.page = page - 1
      const config = { params }
      if (this.productionType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }
      // Changes made for RELIANCE user
      this.$utils
        .callAxios(
          this.CUSTOMER === 'RELIANCE'
            ? codes.requests.getRelianceStores.method
            : codes.requests.getStores.method,
          this.CUSTOMER === 'RELIANCE'
            ? codes.requests.getRelianceStores.url
            : codes.requests.getStores.url,
          config
        )
        .then(res => {
          if (res.status === 204) {
            res.headers['x-totalelements'] = 0
            res.headers['x-totalpages'] = 0
            res.data = { stores: [] }
          }
          this.saveRequestConfig(res.config)
          if (this.CUSTOMER === 'ROGERS') {
            this.tableItems = []
            res.data.stores.forEach(store => {
              const { storeDescription } = store
              const storeDetails = JSON.parse(storeDescription) || {}
              const {
                groupId = '',
                banner = '',
                language = '',
                rPan = '',
                address = ''
              } = storeDetails || {}
              this.tableItems.push({
                ...store,
                groupId,
                banner,
                language,
                rPan,
                address
              })
            })
            for (let i = 0; i < res.data.stores.length; i++) {
              this.typeCountries.push(res.data.stores[i].country)
              this.typeRegions.push(res.data.stores[i].region)
              this.typeCitys.push(res.data.stores[i].city)
            }
            this.typeCountry = [...new Set(this.typeCountries)]
            this.typeRegion = [...new Set(this.typeRegions)]
            this.typeCity = [...new Set(this.typeCitys)]
          } else if (this.CUSTOMER === 'RELIANCE') {
            // Changes made for RELIANCE user
            // Setting the tableItems from storeSummaryList
            this.tableItems = res.data.storeSummaryList
          } else {
            this.tableItems = res.data.stores
          }
          // Changes made for RELIANCE user
          this.totalStores =
            this.CUSTOMER === 'RELIANCE' && res.data !== undefined
              ? res.data.storeSummaryList.length
              : res.data.stores.length
          // Pagination
          this.pageInfoText = this.getPageInfoText(res.headers)
          if (commons.isValidStr(res.headers['x-totalpages'])) {
            this.totalPages = res.headers['x-totalpages'] * 1
          }
          this.page = page
          this.pageIndex = res.headers['x-number'] * 1 + 1
          this.pageCount = res.headers['x-totalpages'] * 1
          this.selected = []
        })
    },
    saveRequestConfig: function (config) {
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: config.params
      }
      for (const key in requestConfig.params) {
        if (requestConfig.params[key] === null) {
          delete requestConfig.params[key]
        }
      }
      this.requestConfig = requestConfig
      console.log('this.requestConfig :>> ', this.requestConfig)
    },
    exportStores () {
      // Export stores data
      // This method is triggered when the export button is clicked
      // It exports the store information as a file
      if (this.tableItems.length < 1) {
        EventBus.$emit('messageAlert', this.$t('No data to export.'))
        return
      }
      exportFiles(this.requestConfig, this.$refs.link, 'StoreList.xlsx')
    },
    getPageInfoText: function (headers) {
      if (headers['x-totalelements'] === 0) return '0 to 0, 0' + ' ' + this.$t('in total')
      return `${headers['x-number'] * 1 * headers['x-size'] + 1}
      ${this.$t('to')} ${(headers['x-number'] * 1 * headers['x-size']) + headers['x-total-count'] * 1},
       ${headers['x-totalelements'] * 1}` + ' ' + this.$t('in total')
    },
    getPlaceholder (input) {
      // Get placeholder text for search inputs
      // This method is used to dynamically retrieve the placeholder text based on the search input
      // return `${input.placeholderType==='select' ? 'Select' : 'input'} ${input.name.toLowerCase()}`
      let placeholder = ''
      switch (input.placeholderType) {
        case 'select':
          placeholder = `Select ${input.name.toLowerCase()}`
          break
        case 'input':
          placeholder = `Input ${input.name.toLowerCase()}`
          break
        default:
          placeholder = `Input ${input.name.toLowerCase()}`
          break
      }
      return placeholder
    },
    buildDeleteStoreReqBody (selected) {
      const reqBody = {}
      reqBody.storeList = selected.map(store => store.store)
      return reqBody
    },
    async deleteStores () {
      // Delete selected stores
      // This method is triggered when the delete button is clicked
      // It deletes the selected stores from the data table
      var accessmenu = this.$store.state.auth.user.accountInfo.accessMenu
      if (this.user.lbsEnable === 'true') {
        // User의 LBS 사용여부가 가능인경우, 우선 LBS 설정을 삭제후 성공 여부에 따라서 Store도 삭제.
        if (accessmenu.indexOf('6100') !== -1) {
          const lbsConfigDeleted = await this.deleteLbsConfiguration()
          if (lbsConfigDeleted === true) {
            this.deleteStore()
          }
        } else {
          this.deleteStore()
        }
      } else {
        this.deleteStore()
      }
    },
    async deleteLbsConfiguration () {
      const storeIds = this.selected.map(store => store.store)
      const params = { company: this.user.company }
      const config = { params }
      let state = true
      for await (const storeId of storeIds) {
        params.store = storeId
        const getUrl =
          configs.ServerAddress + codes.requests.deleteLbsConfiguration.url
        const url = encodeURI(`${getUrl}/${storeId}`)

        state = await this.$utils
          .callAxios(codes.requests.deleteLbsConfiguration.method, url, config)
          .then(res => {
            return true
          })
          .catch(error => {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
            return false
          })
        if (state === false) break
      }
      return state
    },
    deleteStore () {
      const reqBody = this.buildDeleteStoreReqBody(this.selected)
      if (this.$store.state.auth.user.otaEnable === 'true') {
        this.disableOtaSchedule()
      }
      const config = { params: {}, data: reqBody }
      this.$utils
        .callAxios(
          codes.requests.deleteStores.method,
          codes.requests.deleteStores.url,
          config
        )
        .then(res => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.selected = []
          this.page--
          this.getStores(this.page)
          // 선택된 스토어를 삭제시 선택된 스토어 클리어
          const selectedStore = this.$store.state.dataStore.selectedStore
          if (config.data.storeList.includes(selectedStore.code)) {
            this.$store.dispatch('dataStore/UPDATE_SELECTED_STORE', {})
          }
          // Refresh ManagedStores
          EventBus.$emit('setManagedStores')
        })
        .catch(error => {
          if (error.response.data.responseCode === '503') {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to delete store.'))
          }
        })
    },
    async disableOtaSchedule () {
      const storeIds = this.selected.map(store => store.store)
      const params = {}
      const config = { params }
      for await (const storeId of storeIds) {
        const url = encodeURI(
          `${codes.requests.disableOtaSchedule.url}/${storeId}`
        )

        this.$utils
          .callAxios(codes.requests.disableOtaSchedule.method, url, config)
          .then(res => { })
          .catch(error => {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
          })
      }
    },
    getArea (areas, areaName) {
      if (areas[areaName] !== undefined) return areas[areaName]
      areas[areaName] = {}
      return areas[areaName]
    },
    loadStoreLocations (stores) {
      if (!commons.isAllValid(stores)) return []
      const countries = {}
      for (const store of stores) {
        const country = this.getArea(countries, store.country)
        const region = this.getArea(country, store.region)
        this.getArea(region, store.city)
      }
      return countries
    },
    getAreaMsg (idx) {
      return Object.values(msg.areaSelection)[idx]
    },
    getAreaNames (area) {
      if (area !== null && area !== undefined) return [...Object.keys(area)]
    },
    getSelectableAreaNames (areaNames = [], inputs, areas, idx, length) {
      if (idx === length - 1) return areaNames
      // Find selected value's children.
      if (areas === undefined) {
        for (let i = idx + 1; i < length; i++) {
          areaNames.push([])
        }
        return areaNames
      } else {
        areaNames[idx] = this.getAreaNames(areas[inputs[idx].value]) || []
      }
      return this.getSelectableAreaNames(
        areaNames,
        inputs,
        areas[inputs[idx].value],
        ++idx,
        length
      )
    },
    setSelectableAreaNames (inputIdx) {
      // Set selectable area names based on the selected city name
      // This method is triggered when the city name select input is changed
      // It updates the selectable area names options for the area name select input
      const areaNames = this.getSelectableAreaNames(
        [],
        this.searchInputs.select,
        this.countries,
        0,
        this.searchInputs.select.length
      )

      for (let idx = 0; idx < areaNames.length; idx++) {
        // Show processed data.
        this.searchInputs.select[idx + 1].options = areaNames[idx]
      }
      for (let i = 1; i < this.searchInputs.select.length; i++) {
        // Empty every child node's value.
        if (i > inputIdx) this.searchInputs.select[i].value = ''
      }
    },
    // Method to fetch the available timezones
    getTimezones () {
      const config = { params: {} }
      return this.$utils
        .callAxios(
          codes.requests.getTimezones.method,
          codes.requests.getTimezones.url,
          config
        )
        .then(res => {
          return res.data.timezoneList
        })
    },
    async setAreas () {
      // const areas = await this.getAreas()
      // this.areas = this.loadStoreLocations(areas)
    },
    getUserDetails () {
      const config = {
        params: { company: this.user.company, account: this.user.account }
      }
      return this.$utils
        .callAxios(
          codes.requests.getUserDetails.method,
          codes.requests.getUserDetails.url,
          config
        )
        .then(res => {
          return res.data.managedStores
        })
    },
    setPrevSearchInputValues (searchInputs, prevSearchInputs) {
      for (const inputsIdx in searchInputs) {
        searchInputs[inputsIdx] = searchInputs[inputsIdx].map(
          (searchInput, inputIdx) => {
            searchInput.value = prevSearchInputs[inputsIdx][inputIdx].value
            return searchInput
          }
        )
      }
      return searchInputs
    },
    async initiateStoreInfoPage (page) {
      // Initialize the store information page with the selected page number
      // This method is triggered when a specific page number is clicked in the first data table
      // It updates the options.page value and retrieves the corresponding store information
      const managedStores = await this.getUserDetails()
      this.countries = this.loadStoreLocations(managedStores)
      this.searchInputs.select[0].options = this.getAreaNames(this.countries)
      this.searchInputs.select[0].value = null
      // 검색한 적이 있다면 이전 검색어를 불러옴
      const storeInfoSessionData = getTempObj('storeInfo')
      if (
        !commons.isNull(storeInfoSessionData) &&
        !commons.isNull(storeInfoSessionData.searchInputs)
      ) {
        this.searchInputs = this.setPrevSearchInputValues(
          this.searchInputs,
          storeInfoSessionData.searchInputs
        )
        this.setSelectableAreaNames(2)
      }
      this.getTimezones().then(timezones => {
        this.timezones = timezones.map(timezone => timezone.name)
      })
      this.getStores(page)
    }
  }
}
</script>
<style scoped>
.hidden {
  display: none;
}

.pageAlign {
  float: right;
}

.bl-1 {
  border-left: 1px solid #ececec;
}

.bt-1 {
  border-top: 1px solid #ececec;
}

.cursor-header:hover {
  cursor: pointer;
}

.sort-icon-header:hover .sort-icon {
  opacity: 0.5;
}

.sort-icon {
  translate: 0.3s;
}

.sort-active {
  opacity: 1;
}

.sort-deactive {
  opacity: 0;
}

/* css changes for rogers */
.row-class:hover {
  cursor: pointer;
}
/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}
.theme--light.v-chip:not(.v-chip--active) {
  background: #e0e0e0;
  margin: 5px !important;
}
::v-deep ::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

::v-deep.v-application .grey {
  background-color: #797979 !important;
  border-color: #797979 !important;
}
::v-deep.search_input.form-input.v-text-field--outlined {
  padding: 0px !important;
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

::v-deep.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
::v-deep.v-text-field {
  /* margin-top: 12px !important; */
  padding-top: 0px !important;
}

::v-deep.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 0 0 0 !important;
}

::v-deep.v-text-field>.v-input__control>.v-input__slot:after {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 0 0 0 !important;
}
/* ::v-deep.v-list-item {
  border-bottom: 1px solid #ececec !important;
} */

::v-deep.columnDropdown.v-select>.v-input__control>.v-input__slot {
  background-color: #797979 !important;
  height: 41px !important;
  border-radius: 5px !important;
  padding: 10px;
}

::v-deep.v-select {
  height: 35px !important;
}

::v-deep.v-sheet.v-list:not(.v-sheet--outlined) {
  padding: 0px 0px 0px 10px;
}

/* ::v-deep.v-text-field input::placeholder {
  color: #ffff;
} */

::v-deep.v-menu__content>.v-sheet.v-list:not(.v-sheet--outlined) {
  padding-left: 10px !important;
  width: 238px !important;
  top: 268px !important;
}
/* by G */
/* ::v-deep.v-list {
  padding-left: 10px !important;
  width: 238px !important;
  top: 268px !important;
} */

::v-deep.v-menu__content.theme--light.menuable__content__active {
  padding-left: 10px !important;
  width: 238px !important;
  top: 268px !important;
  color: red !important;
}

::v-deep.v-menu__content .theme--light .menuable__content__active {
  top: 268px !important;
}

::v-deep.v-select--is-menu-active {
  color: #4B4B4B !important;
  background-color: #FFFF !important;
  font-size: 13px !important;
}

::v-deep .theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
/* Let's get this party started */
.v-menu__content::-webkit-scrollbar {
  width: 8.11px;
}

/* Track */
.v-menu__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ECECEC;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  z-index: 8;
}

/* Handle */
.v-menu__content::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #00000080;
  z-index: 8;
  -webkit-box-shadow: inset 0 0 6px #ECECEC;
}
/* filter btn */
.filter_button {
  line-height: 34px;
  height: 45px !important;
  font-size: var(--font17);
  background: #ECECEC;
  color: #4B4B4B;
  padding: 5px;
  border-radius: 3px;
  letter-spacing: -0.005em;
  justify-content: left;
  width: 100% !important;
  box-shadow: none !important;
}
/* in checkbox filter */
.filter_text {
  color: #4B4B4B;
  font-size: 13px;
  opacity: 1
}
::v-deep.data-table-header{
height: 50px !important;
  white-space: nowrap !important;
}
.filter_count {
  color: #797979;
  font-size: 13px;
  opacity: 1
}
.filters {
  background: #ECECEC;
  border-radius: 4px;
  height: 469px;
  overflow-x: scroll;
  padding: 16px;
}
</style>
