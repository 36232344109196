var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.CUSTOMER !== "ROGERS"
    ? _c(
        "v-dialog",
        {
          key: _vm.showAddStoreModal,
          attrs: { scrollable: "", persistent: "", width: "850" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "btn",
                            class: { hidden: _vm.SingleUser },
                            attrs: { disabled: _vm.buttonDisabled, text: "" },
                            on: {
                              click: function($event) {
                                return _vm.resetValues()
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_vm._v(_vm._s(_vm.$t("Add")))]
                    )
                  ]
                }
              }
            ],
            null,
            false,
            61821098
          ),
          model: {
            value: _vm.showAddStoreModal,
            callback: function($$v) {
              _vm.showAddStoreModal = $$v
            },
            expression: "showAddStoreModal"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup " },
            [
              _c("v-card-title", [
                _c("h3", [
                  _c("i", { staticClass: "ico ico-store" }),
                  _vm._v(_vm._s(_vm.$t("Add Store")))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _vm._l(_vm.storeInputs, function(inputs, idx) {
                    return _c(
                      "v-row",
                      { key: idx },
                      _vm._l(inputs, function(input, colIdx) {
                        return _c(
                          "v-col",
                          {
                            key: input.key,
                            staticClass: "pt-2",
                            attrs: { cols: "4" },
                            on: {
                              change: function($event) {
                                return _vm.setValue(input.key, input.value)
                              }
                            }
                          },
                          [
                            input.selectable
                              ? _c("v-select", {
                                  ref: input.key,
                                  refInFor: true,
                                  staticClass: "form-select",
                                  class: _vm.getStoreInputSelectClass(
                                    input.name
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    items: input.options,
                                    label: _vm.$t(input.label) + "*",
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: "",
                                    attach: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.setSelectableAreaNames(idx)
                                      _vm.setConditionsforChLbs(input)
                                    },
                                    keydown: _vm.clearTabindex
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                              : input.selectable &&
                                input.key !== "storeDescription"
                              ? _c("v-select", {
                                  ref: input.key,
                                  refInFor: true,
                                  staticClass: "form-select",
                                  class: _vm.getStoreInputSelectClass(
                                    input.name
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    items: input.options,
                                    label: "" + _vm.$t(input.label),
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: "",
                                    attach: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.setSelectableAreaNames(idx)
                                      _vm.setConditionsforChLbs(input)
                                    },
                                    keydown: _vm.clearTabindex
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                              : !input.selectable &&
                                input.key !== "storeDescription"
                              ? _c("v-text-field", {
                                  ref: input.key,
                                  refInFor: true,
                                  class: _vm.getStoreInputTextClass(
                                    input.key,
                                    input.key === "countryInput" ||
                                      input.key === "regionInput" ||
                                      input.key === "cityInput"
                                      ? 1
                                      : idx
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    disabled: idx === 0,
                                    label: _vm.$t(input.label) + "*",
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: ""
                                  },
                                  on: {
                                    keypress: _vm.handleKeyUp,
                                    keydown: _vm.clearTabindex
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.isAreaInput(input.key)
                                        ? {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.areaAddDisabled,
                                                      text: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addAreaValue(
                                                          input.value,
                                                          idx,
                                                          colIdx - 1,
                                                          input.key
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/btn-save.jpg"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        : null
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                              : _c("v-text-field", {
                                  ref: input.key,
                                  refInFor: true,
                                  class: _vm.getStoreInputTextClass(
                                    input.key,
                                    idx
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    disabled: idx === 0,
                                    label: "" + _vm.$t(input.label),
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: ""
                                  },
                                  on: {
                                    keypress: _vm.handleKeyUp,
                                    keydown: _vm.clearTabindex
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.isAreaInput(input.key)
                                        ? {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.areaAddDisabled,
                                                      text: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addAreaValue(
                                                          input.value,
                                                          idx,
                                                          colIdx - 1,
                                                          input.key
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/btn-save.jpg"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        : null
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  }),
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "advancedSetting",
                      attrs: { flat: "", accordion: "" },
                      model: {
                        value: _vm.advancedSettingVisible,
                        callback: function($$v) {
                          _vm.advancedSettingVisible = $$v
                        },
                        expression: "advancedSettingVisible"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "pl-0 pr-0" },
                            [
                              _c("i", { staticClass: "throwline" }),
                              _vm._v(_vm._s(_vm.$t("Show Advanced Setting")))
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _vm._l(_vm.advStoreInputs, function(
                                advInputs,
                                idx
                              ) {
                                return _c(
                                  "v-row",
                                  { key: idx },
                                  _vm._l(advInputs, function(advInput) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: advInput.name,
                                        staticClass: "pt-2 pb-2",
                                        attrs: { cols: "4" }
                                      },
                                      [
                                        advInput.selectable
                                          ? _c("v-select", {
                                              ref: advInput.key,
                                              refInFor: true,
                                              class: _vm.getStoreInputSelectClass(
                                                advInput.name
                                              ),
                                              attrs: {
                                                label: _vm.$t(advInput.label),
                                                placeholder: _vm.getPlaceholder(
                                                  advInput
                                                ),
                                                items: advInput.options,
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                clearable: ""
                                              },
                                              on: {
                                                keydown: _vm.clearTabindex
                                              },
                                              model: {
                                                value: advInput.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    advInput,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "advInput.value"
                                              }
                                            })
                                          : _c("v-text-field", {
                                              ref: advInput.key,
                                              refInFor: true,
                                              staticClass: "form-input",
                                              attrs: {
                                                min: advInput.min
                                                  ? advInput.min
                                                  : -100000,
                                                max: advInput.max
                                                  ? advInput.max
                                                  : 100000,
                                                disabled: advInput.disabled,
                                                label: _vm.$t(advInput.label),
                                                placeholder: _vm.getPlaceholder(
                                                  advInput
                                                ),
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                type: "number"
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleAdvInputKeyup(
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: advInput.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    advInput,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "advInput.value"
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              }),
                              _vm.notifications
                                ? _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "mt-5",
                                            staticStyle: {
                                              "padding-left": "0px",
                                              color: "#0A2640",
                                              "line-height": "1",
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _c("u", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "SERVER SENT EVENT SETTINGS"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c("v-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml-1",
                                                  staticStyle: {
                                                    "margin-top": "24px"
                                                  }
                                                },
                                                [
                                                  _vm.notifications
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("v-label", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Picking"
                                                                  )
                                                                ) +
                                                                " : "
                                                            )
                                                          ]),
                                                          _c("v-switch", {
                                                            staticClass:
                                                              "form-switch",
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              "vertical-align":
                                                                "sub",
                                                              display:
                                                                "inline-block"
                                                            },
                                                            attrs: {
                                                              inset: "",
                                                              "hide-details":
                                                                "",
                                                              solo: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.picking,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.picking = $$v
                                                              },
                                                              expression:
                                                                "picking"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.notifications
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "ml-4" },
                                                        [
                                                          _c("v-label", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Alarm"
                                                                  )
                                                                ) +
                                                                " : "
                                                            )
                                                          ]),
                                                          _c("v-switch", {
                                                            staticClass:
                                                              "form-switch",
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              "vertical-align":
                                                                "sub",
                                                              display:
                                                                "inline-block"
                                                            },
                                                            attrs: {
                                                              inset: "",
                                                              "hide-details":
                                                                "",
                                                              solo: ""
                                                            },
                                                            model: {
                                                              value: _vm.alarm,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.alarm = $$v
                                                              },
                                                              expression:
                                                                "alarm"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "mt-3",
                                                attrs: { cols: "7" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "form-input",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "SSE Event URL for Subscription Details"
                                                    ),
                                                    placeholder: "URL",
                                                    outlined: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    disabled: ""
                                                  },
                                                  model: {
                                                    value: _vm.sseUrl,
                                                    callback: function($$v) {
                                                      _vm.sseUrl = $$v
                                                    },
                                                    expression: "sseUrl"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleStoreAddBtnClick()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAddStore()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", width: "850" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "btn",
                          attrs: { disabled: _vm.buttonDisabled, text: "" },
                          on: {
                            click: function($event) {
                              return _vm.resetValues()
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("Add")))]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showAddStoreModal,
            callback: function($$v) {
              _vm.showAddStoreModal = $$v
            },
            expression: "showAddStoreModal"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup" },
            [
              _c("v-card-title", [
                _c("h3", [
                  _c("i", { staticClass: "ico ico-store" }),
                  _vm._v(_vm._s(_vm.$t("Add Store")))
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _vm._l(_vm.newStoreInputs, function(inputs, idx) {
                    return _c(
                      "v-row",
                      { key: idx },
                      _vm._l(inputs, function(input, colIdx) {
                        return _c(
                          "v-col",
                          {
                            key: input.name,
                            staticClass: "pt-2 pb-2",
                            attrs: { cols: "4" },
                            on: {
                              change: function($event) {
                                return _vm.setValue(input.key, input.value)
                              }
                            }
                          },
                          [
                            input.selectable && input.key === "company"
                              ? _c("v-select", {
                                  ref: input.key,
                                  refInFor: true,
                                  staticClass: "form-select",
                                  class: _vm.getStoreInputSelectClass(
                                    input.name
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    items: input.options,
                                    label: _vm.$t(input.label) + "*",
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: "",
                                    attach: "",
                                    disabled: input.key === "company"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.setSelectableAreaNames(idx)
                                      _vm.setConditionsforChLbs(input)
                                    },
                                    keydown: _vm.clearTabindex
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                              : input.selectable &&
                                input.key !== "storeDescription"
                              ? _c("v-select", {
                                  ref: input.key,
                                  refInFor: true,
                                  staticClass: "form-select",
                                  class: _vm.getStoreInputSelectClass(
                                    input.name
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    items: input.options,
                                    label: "" + _vm.$t(input.label),
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: "",
                                    attach: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.setSelectableAreaNames(idx)
                                      _vm.setConditionsforChLbs(input)
                                    },
                                    keydown: _vm.clearTabindex
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                              : !input.selectable &&
                                input.key !== "storeDescription"
                              ? _c("v-text-field", {
                                  ref: input.key,
                                  refInFor: true,
                                  class: _vm.getStoreInputTextClass(
                                    input.key,
                                    idx
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    disabled:
                                      idx === 0 && input.key === "company",
                                    label: _vm.$t(input.label) + "*",
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: ""
                                  },
                                  on: {
                                    keypress: _vm.handleKeyUp,
                                    keydown: _vm.clearTabindex
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.isAreaInput(input.key)
                                        ? {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.areaAddDisabled,
                                                      text: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addAreaValue(
                                                          input.value,
                                                          idx,
                                                          colIdx - 1,
                                                          input.key
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/btn-save.jpg"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        : null
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                              : _c("v-text-field", {
                                  ref: input.key,
                                  refInFor: true,
                                  class: _vm.getStoreInputTextClass(
                                    input.key,
                                    idx
                                  ),
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    disabled:
                                      idx === 0 && input.key === "company",
                                    label: "" + _vm.$t(input.label),
                                    placeholder: _vm.getPlaceholder(input),
                                    clearable: ""
                                  },
                                  on: {
                                    keypress: _vm.handleKeyUp,
                                    keydown: _vm.clearTabindex
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm.isAreaInput(input.key)
                                        ? {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.areaAddDisabled,
                                                      text: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addAreaValue(
                                                          input.value,
                                                          idx,
                                                          colIdx - 1,
                                                          input.key
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/img/btn-save.jpg"),
                                                        alt: ""
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        : null
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: input.value,
                                    callback: function($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value"
                                  }
                                })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  }),
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "advancedSetting",
                      attrs: { flat: "", accordion: "" },
                      model: {
                        value: _vm.advancedSettingVisible,
                        callback: function($$v) {
                          _vm.advancedSettingVisible = $$v
                        },
                        expression: "advancedSettingVisible"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "pl-0 pr-0" },
                            [
                              _c("i", { staticClass: "throwline" }),
                              _vm._v(_vm._s(_vm.$t("Show Advanced Setting")))
                            ]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _vm._l(_vm.advStoreInputs, function(
                                advInputs,
                                idx
                              ) {
                                return _c(
                                  "v-row",
                                  { key: idx },
                                  _vm._l(advInputs, function(advInput) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: advInput.name,
                                        staticClass: "pt-2 pb-2",
                                        attrs: { cols: "4" }
                                      },
                                      [
                                        advInput.selectable
                                          ? _c("v-select", {
                                              ref: advInput.key,
                                              refInFor: true,
                                              class: _vm.getStoreInputSelectClass(
                                                advInput.name
                                              ),
                                              attrs: {
                                                label: _vm.$t(advInput.label),
                                                placeholder: _vm.getPlaceholder(
                                                  advInput
                                                ),
                                                items: advInput.options,
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                clearable: ""
                                              },
                                              on: {
                                                keydown: _vm.clearTabindex
                                              },
                                              model: {
                                                value: advInput.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    advInput,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "advInput.value"
                                              }
                                            })
                                          : _c("v-text-field", {
                                              ref: advInput.key,
                                              refInFor: true,
                                              staticClass: "form-input",
                                              attrs: {
                                                min: advInput.min
                                                  ? advInput.min
                                                  : -100000,
                                                max: advInput.max
                                                  ? advInput.max
                                                  : 100000,
                                                disabled: advInput.disabled,
                                                label: _vm.$t(advInput.label),
                                                placeholder: _vm.getPlaceholder(
                                                  advInput
                                                ),
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                type: "number"
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.handleAdvInputKeyup(
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: advInput.value,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    advInput,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "advInput.value"
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              }),
                              _vm.notifications
                                ? _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "mt-5",
                                            staticStyle: {
                                              "padding-left": "0px",
                                              color: "#0A2640",
                                              "line-height": "1",
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _c("u", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "SERVER SENT EVENT SETTINGS"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c("v-col", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml-1",
                                                  staticStyle: {
                                                    "margin-top": "24px"
                                                  }
                                                },
                                                [
                                                  _vm.notifications
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("v-label", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Picking"
                                                                  )
                                                                ) +
                                                                " : "
                                                            )
                                                          ]),
                                                          _c("v-switch", {
                                                            staticClass:
                                                              "form-switch",
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              "vertical-align":
                                                                "sub",
                                                              display:
                                                                "inline-block"
                                                            },
                                                            attrs: {
                                                              inset: "",
                                                              "hide-details":
                                                                "",
                                                              solo: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.picking,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.picking = $$v
                                                              },
                                                              expression:
                                                                "picking"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.notifications
                                                    ? _c(
                                                        "span",
                                                        { staticClass: "ml-4" },
                                                        [
                                                          _c("v-label", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Alarm"
                                                                  )
                                                                ) +
                                                                " : "
                                                            )
                                                          ]),
                                                          _c("v-switch", {
                                                            staticClass:
                                                              "form-switch",
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              "vertical-align":
                                                                "sub",
                                                              display:
                                                                "inline-block"
                                                            },
                                                            attrs: {
                                                              inset: "",
                                                              "hide-details":
                                                                "",
                                                              solo: ""
                                                            },
                                                            model: {
                                                              value: _vm.alarm,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.alarm = $$v
                                                              },
                                                              expression:
                                                                "alarm"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "mt-3",
                                                attrs: { cols: "7" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "form-input",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "SSE Event URL for Subscription Details"
                                                    ),
                                                    placeholder: "URL",
                                                    outlined: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    disabled: ""
                                                  },
                                                  model: {
                                                    value: _vm.sseUrl,
                                                    callback: function($$v) {
                                                      _vm.sseUrl = $$v
                                                    },
                                                    expression: "sseUrl"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleStoreAddBtnClick()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAddStore()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }