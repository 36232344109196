var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "retain-focus": false, width: "750" },
      on: {
        "click:outside": function($event) {
          return _vm.toggleSummary()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticStyle: { color: "#4d4f5c !important" },
                      attrs: {
                        disabled: _vm.buttonDisabled,
                        text: "",
                        small: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.item.storeName))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.summaryVisible,
        callback: function($$v) {
          _vm.summaryVisible = $$v
        },
        expression: "summaryVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup" },
        [
          _c("h3", { staticClass: "page-title-bar" }, [
            _c("i", { staticClass: "ico ico-store" }),
            _vm._v(_vm._s(_vm.$t("Store Detail")))
          ]),
          _c("div", { staticClass: "main-section" }, [
            _c("h3", { staticClass: "tit-section" }, [
              _vm._v(_vm._s(_vm.$t("Label Status Monitoring")))
            ]),
            _c(
              "div",
              { staticClass: "group" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("dl", { staticClass: "stateBox" }, [
                        _c("dt", [_vm._v(_vm._s(_vm.$t("Update Success")))]),
                        _c("dd", [
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getPercentage(
                                      _vm.storeSummary.updatedLabelCount,
                                      _vm.storeSummary.totalUpdatedLabelCount
                                    )
                                  ) +
                                  "%"
                              )
                            ])
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.storeSummary.updatedLabelCount) +
                                "ea / " +
                                _vm._s(
                                  _vm.storeSummary.totalUpdatedLabelCount
                                ) +
                                "ea"
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("v-col", [
                      _c("dl", { staticClass: "stateBox" }, [
                        _c("dt", [_vm._v(_vm._s(_vm.$t("Processing")))]),
                        _c("dd", [
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.getPercentage(
                                    _vm.storeSummary.inProgressLabelCount,
                                    _vm.storeSummary.totalUpdatedLabelCount
                                  )
                                ) + "%"
                              )
                            ])
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.storeSummary.inProgressLabelCount) +
                                "ea / " +
                                _vm._s(
                                  _vm.storeSummary.totalUpdatedLabelCount
                                ) +
                                "ea"
                            )
                          ])
                        ])
                      ])
                    ]),
                    _c("v-col", [
                      _c(
                        "dl",
                        {
                          staticClass: "stateBox",
                          class: _vm.getColorState(
                            _vm.getPercentage(
                              _vm.storeSummary.notUpdatedLabelCount,
                              _vm.storeSummary.totalUpdatedLabelCount
                            ),
                            _vm.labelUpdatedCaution,
                            _vm.labelUpdatedDanger
                          )
                        },
                        [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Update Failure")))]),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getPercentage(
                                      _vm.storeSummary.notUpdatedLabelCount,
                                      _vm.storeSummary.totalUpdatedLabelCount
                                    )
                                  ) + "%"
                                )
                              ])
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.storeSummary.notUpdatedLabelCount) +
                                  "ea / " +
                                  _vm._s(
                                    _vm.storeSummary.totalUpdatedLabelCount
                                  ) +
                                  "ea"
                              )
                            ])
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("dl", { staticClass: "stateBox" }, [
                        _c("dt", [_vm._v(_vm._s(_vm.$t("Total Product")))]),
                        _c("dd", [
                          _c("p", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.storeSummary.totalProductCount))
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("v-col", [
                      _c("dl", { staticClass: "stateBox" }, [
                        _c("dt", [
                          _vm._v(_vm._s(_vm.$t("Total Assigned Product")))
                        ]),
                        _c("dd", [
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.storeSummary.totalAssignedProductCount
                                )
                              )
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("v-col", [
                      _c("dl", { staticClass: "stateBox" }, [
                        _c("dt", [_vm._v(_vm._s(_vm.$t("Total Label")))]),
                        _c("dd", [
                          _c("p", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.storeSummary.totalLabelCount))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "main-section mt-4" }, [
            _c("h3", { staticClass: "tit-section" }, [
              _vm._v(_vm._s(_vm.$t("Exception Status Monitoring")))
            ]),
            _c(
              "div",
              { staticClass: "group" },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c(
                        "dl",
                        {
                          staticClass: "stateBox",
                          class: _vm.getColorState(
                            _vm.getPercentage(
                              _vm.storeSummary.offlineLabelCount,
                              _vm.storeSummary.totalUpdatedLabelCount
                            ),
                            _vm.labelOfflineCaution,
                            _vm.labelOfflineDanger
                          )
                        },
                        [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Offline ESL")))]),
                          _c("dd", [
                            _c("p", [
                              _vm.storeSummary.offlineLabelCount !== -1
                                ? _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.storeSummary.offlineLabelCount)
                                    )
                                  ])
                                : _c("strong", [_vm._v("N/A")])
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("v-col", [
                      _c(
                        "dl",
                        {
                          staticClass: "stateBox",
                          class: _vm.getColorState(
                            _vm.getPercentage(
                              _vm.storeSummary.badSignalLabelCount,
                              _vm.storeSummary.totalUpdatedLabelCount
                            ),
                            _vm.labelSignalCaution,
                            _vm.labelSignalDanger
                          )
                        },
                        [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Bad Signal")))]),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.storeSummary.badSignalLabelCount)
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("v-col", [
                      _c(
                        "dl",
                        {
                          staticClass: "stateBox",
                          class: _vm.getColorState(
                            _vm.getPercentage(
                              _vm.storeSummary.lowBatteryCount,
                              _vm.storeSummary.totalUpdatedLabelCount
                            ),
                            _vm.labelLowBatteryCaution,
                            _vm.labelLowBatteryDanger
                          )
                        },
                        [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Low Battery")))]),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.storeSummary.lowBatteryCount))
                              ])
                            ])
                          ])
                        ]
                      )
                    ]),
                    _c("v-col", [
                      _c(
                        "dl",
                        {
                          staticClass: "stateBox",
                          class: _vm.getColorState(
                            _vm.getPercentage(
                              _vm.storeSummary.misUsedLabelCount,
                              _vm.storeSummary.totalUpdatedLabelCount
                            ),
                            _vm.labelMisusedCaution,
                            _vm.labelMisusedDanger
                          )
                        },
                        [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Misused")))]),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.storeSummary.misUsedLabelCount)
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.goToStore()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Go to the store")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.toggleSummary()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }