<template>
  <v-dialog
   v-if="CUSTOMER !== 'ROGERS'"
   v-model="showAddStoreModal"
   :key="showAddStoreModal"
   scrollable
   persistent
   width="850"
 >
   <template v-slot:activator="{ on, attrs }">
     <v-btn
       v-bind="attrs"
       v-on="on"
       :class="{hidden:SingleUser}"
       :disabled="buttonDisabled"
       text
       @click="resetValues()"
       class="btn"
       >{{ $t("Add") }}</v-btn
     >
   </template>
   <v-card class="popup ">
     <v-card-title>
       <h3><i class="ico ico-store"></i>{{ $t("Add Store") }}</h3>
   </v-card-title>
       <v-card-text >
         <!-- Old Design -->
         <v-row v-for="(inputs, idx) in storeInputs" :key="idx">
           <v-col
             v-for="(input, colIdx) in inputs"
             :key="input.key"
             class="pt-2"
             cols="4"
             @change="setValue(input.key,input.value)"
           >
             <!-- <v-select
                 v-if="input.selectable" outlined dense hide-details
                 v-model="input.value"
                 :ref="input.key"
                 @change="setSelectableAreaNames(idx)"
                 :items="input.options"
                 :class="getStoreInputSelectClass(input.name)"
                 class="form-select"
                 :label="`${$t(input.label)}*`"
                 :placeholder="getPlaceholder(input)"
                 clearable
                 attach
               >
               </v-select> -->
             <v-select
               v-if="input.selectable"
               outlined
               dense
               hide-details
               v-model="input.value"
               :ref="input.key"
               @change="
                 setSelectableAreaNames(idx);
                 setConditionsforChLbs(input);
               "
               :items="input.options"
               :class="getStoreInputSelectClass(input.name)"
               class="form-select"
               :label="`${$t(input.label)}*`"
               :placeholder="getPlaceholder(input)"
               clearable
               attach
               @keydown="clearTabindex"
             >
             </v-select>
             <v-select
               v-else-if="input.selectable && input.key !== 'storeDescription'"
               outlined
               dense
               hide-details
               v-model="input.value"
               :ref="input.key"
               @change="
                 setSelectableAreaNames(idx);
                 setConditionsforChLbs(input);
               "
               :items="input.options"
               :class="getStoreInputSelectClass(input.name)"
               class="form-select"
               :label="`${$t(input.label)}`"
               :placeholder="getPlaceholder(input)"
               clearable
               attach
               @keydown="clearTabindex"
             >
             </v-select>
             <v-text-field
               v-else-if="
                 !input.selectable && input.key !== 'storeDescription'
               "
               outlined
               dense
               hide-details
               :ref="input.key"
               v-model="input.value"
               :disabled="idx === 0"
               :class="
                 getStoreInputTextClass(
                   input.key,
                   input.key === 'countryInput' ||
                     input.key === 'regionInput' ||
                     input.key === 'cityInput'
                     ? 1
                     : idx
                 )
               "
               :label="`${$t(input.label)}*`"
               :placeholder="getPlaceholder(input)"
               clearable
               @keypress="handleKeyUp"
               @keydown="clearTabindex"
             >
               <template v-if="isAreaInput(input.key)" v-slot:append>
                 <v-btn
                   :disabled="areaAddDisabled"
                   @click="
                     addAreaValue(input.value, idx, colIdx - 1, input.key)
                   "
                   text
                 >
                   <img src="@/assets/img/btn-save.jpg" alt="" />
                 </v-btn>
               </template>
             </v-text-field>
             <v-text-field
               v-else
               outlined
               dense
               hide-details
               :ref="input.key"
               v-model="input.value"
               :disabled="idx === 0"
               :class="getStoreInputTextClass(input.key, idx)"
               :label="`${$t(input.label)}`"
               :placeholder="getPlaceholder(input)"
               clearable
               @keypress="handleKeyUp"
               @keydown="clearTabindex"
             >
               <template v-if="isAreaInput(input.key)" v-slot:append>
                 <v-btn
                   :disabled="areaAddDisabled"
                   @click="
                     addAreaValue(input.value, idx, colIdx - 1, input.key)
                   "
                   text
                 >
                   <img src="@/assets/img/btn-save.jpg" alt="" />
                 </v-btn>
               </template>
             </v-text-field>
           </v-col>
         </v-row>
         <v-expansion-panels
           flat
           accordion
           class="advancedSetting"
           v-model="advancedSettingVisible"
         >
           <v-expansion-panel >
             <v-expansion-panel-header class="pl-0 pr-0"
               ><i class="throwline"></i
               >{{ $t("Show Advanced Setting") }}</v-expansion-panel-header
             >
             <v-expansion-panel-content>
               <v-row v-for="(advInputs, idx) in advStoreInputs" :key="idx">
                 <v-col
                   v-for="advInput in advInputs"
                   :key="advInput.name"
                   class="pt-2 pb-2"
                   cols="4"
                 >
                   <v-select
                     v-if="advInput.selectable"
                     :label="$t(advInput.label)"
                     :placeholder="getPlaceholder(advInput)"
                     :items="advInput.options"
                     v-model="advInput.value"
                     :ref="advInput.key"
                     :class="getStoreInputSelectClass(advInput.name)"
                     outlined
                     dense
                     hide-details
                     clearable
                     @keydown="clearTabindex"
                   ></v-select>
                   <v-text-field
                     v-else
                     v-model="advInput.value"
                     :ref="advInput.key"
                     @keyup.prevent="handleAdvInputKeyup"
                     :min="advInput.min ? advInput.min : -100000"
                     :max="advInput.max ? advInput.max : 100000"
                     class="form-input"
                     :disabled="advInput.disabled"
                     :label="$t(advInput.label)"
                     :placeholder="getPlaceholder(advInput)"
                     outlined
                     dense
                     hide-details
                     type="number"
                   >
                   </v-text-field>
                 </v-col>
               </v-row>

               <!-- //SSE Config -->
                <v-row v-if="notifications">
        <v-col cols="12">
        <h3 class="mt-5" style="padding-left: 0px;color: #0A2640;line-height: 1;font-weight: bold;">
          <u>{{ $t('SERVER SENT EVENT SETTINGS') }}</u></h3>
        <div class="row">
           <v-col>
          <div class="ml-1" style="margin-top: 24px;">
            <span v-if="notifications">
            <v-label> {{ $t('Picking') }} &nbsp;: </v-label>
            <v-switch inset hide-details solo class="form-switch"  v-model="picking" style="margin-left: 10px;
             vertical-align: sub;display: inline-block;"></v-switch>
            </span>
            <span class="ml-4" v-if="notifications">
              <v-label> {{ $t('Alarm') }} &nbsp;: </v-label>
              <v-switch inset hide-details solo class="form-switch"  v-model="alarm" style="margin-left: 10px;
              vertical-align: sub;display: inline-block;"></v-switch>
            </span>
          </div>
        </v-col>
        <v-col cols="7" class="mt-3">
          <v-text-field
                    class="form-input"
                    :label="$t('SSE Event URL for Subscription Details')"
                     placeholder="URL"
                     outlined
                     dense
                     hide-details
                     v-model="sseUrl"
                     disabled
                   >
          </v-text-field>
        </v-col>
        </div>

      </v-col>
    </v-row>

    <!-- //End SSE Config -->
             </v-expansion-panel-content>
           </v-expansion-panel>
         </v-expansion-panels>

       </v-card-text>
       <v-card-actions class="d-flex justify-center">
         <v-btn text icon @click="handleStoreAddBtnClick()" class="btn">{{
           $t("Save")
         }}</v-btn>
         <v-btn text icon @click="cancelAddStore()" class="btn">{{
           $t("Cancel")
         }}</v-btn>
       </v-card-actions>

   </v-card>
 </v-dialog>
   <v-dialog
   v-else
   v-model="showAddStoreModal"
   persistent
   scrollable
   width="850"
 >
   <template v-slot:activator="{ on, attrs }">
     <v-btn
       v-bind="attrs"
       v-on="on"
       :disabled="buttonDisabled"
       text
       class="btn"
       @click="resetValues()"
       >{{ $t("Add") }}</v-btn
     >
   </template>
   <v-card class="popup" >

       <v-card-title>
       <h3><i class="ico ico-store"></i>{{ $t("Add Store") }}</h3>
       </v-card-title>
     <v-card-text>

         <!-- New Design -->
         <v-row v-for="(inputs, idx) in newStoreInputs" :key="idx">
           <v-col
             v-for="(input, colIdx) in inputs"
             :key="input.name"
             class="pt-2 pb-2"
             cols="4"
             @change="setValue(input.key,input.value)"

           >
             <!-- <v-select
                 v-if="input.selectable" outlined dense hide-details
                 v-model="input.value"
                 :ref="input.key"
                 @change="setSelectableAreaNames(idx)"
                 :items="input.options"
                 :class="getStoreInputSelectClass(input.name)"
                 class="form-select"
                 :label="`${$t(input.label)}*`"
                 :placeholder="getPlaceholder(input)"
                 clearable
                 attach
               >
               </v-select> -->
             <v-select
               v-if="input.selectable && input.key === 'company'"
               outlined
               dense
               hide-details
               v-model="input.value"
               :ref="input.key"
               @change="
                 setSelectableAreaNames(idx);
                 setConditionsforChLbs(input);
               "
               :items="input.options"
               :class="getStoreInputSelectClass(input.name)"
               class="form-select"
               :label="`${$t(input.label)}*`"
               :placeholder="getPlaceholder(input)"
               clearable
               attach
               @keydown="clearTabindex"
               :disabled="input.key === 'company'"
             >
             </v-select>
             <v-select
               v-else-if="input.selectable && input.key !== 'storeDescription'"
               outlined
               dense
               hide-details
               v-model="input.value"
               :ref="input.key"
               @change="
                 setSelectableAreaNames(idx);
                 setConditionsforChLbs(input);
               "
               :items="input.options"
               :class="getStoreInputSelectClass(input.name)"
               class="form-select"
               :label="`${$t(input.label)}`"
               :placeholder="getPlaceholder(input)"
               clearable
               attach
               @keydown="clearTabindex"
             >
             </v-select>
             <v-text-field
               v-else-if="
                 !input.selectable && input.key !== 'storeDescription'
               "
               outlined
               dense
               hide-details
               :ref="input.key"
               v-model="input.value"
               :disabled="idx === 0 && input.key === 'company'"
               :class="getStoreInputTextClass(input.key, idx)"
               :label="`${$t(input.label)}*`"
               :placeholder="getPlaceholder(input)"
               clearable
               @keypress="handleKeyUp"
               @keydown="clearTabindex"
             >
               <template v-if="isAreaInput(input.key)" v-slot:append>
                 <v-btn
                   :disabled="areaAddDisabled"
                   @click="
                     addAreaValue(input.value, idx, colIdx - 1, input.key)
                   "
                   text
                 >
                   <img src="@/assets/img/btn-save.jpg" alt="" />
                 </v-btn>
               </template>
             </v-text-field>
             <v-text-field
               v-else
               outlined
               dense
               hide-details
               :ref="input.key"
               v-model="input.value"
               :disabled="idx === 0 && input.key === 'company'"
               :class="getStoreInputTextClass(input.key, idx)"
               :label="`${$t(input.label)}`"
               :placeholder="getPlaceholder(input)"
               clearable
               @keypress="handleKeyUp"
               @keydown="clearTabindex"
             >
               <template v-if="isAreaInput(input.key)" v-slot:append>
                 <v-btn
                   :disabled="areaAddDisabled"
                   @click="
                     addAreaValue(input.value, idx, colIdx - 1, input.key)
                   "
                   text
                 >
                   <img src="@/assets/img/btn-save.jpg" alt="" />
                 </v-btn>
               </template>
             </v-text-field>
           </v-col>
         </v-row>
         <v-expansion-panels
           flat
           accordion
           class="advancedSetting"
           v-model="advancedSettingVisible"
         >
           <v-expansion-panel >
             <v-expansion-panel-header class="pl-0 pr-0"
               ><i class="throwline"></i
               >{{ $t("Show Advanced Setting") }}</v-expansion-panel-header
             >
             <v-expansion-panel-content>
               <v-row v-for="(advInputs, idx) in advStoreInputs" :key="idx">
                 <v-col
                   v-for="advInput in advInputs"
                   :key="advInput.name"
                   class="pt-2 pb-2"
                   cols="4"
                 >
                   <v-select
                     v-if="advInput.selectable"
                     :label="$t(advInput.label)"
                     :placeholder="getPlaceholder(advInput)"
                     :items="advInput.options"
                     v-model="advInput.value"
                     :ref="advInput.key"
                     :class="getStoreInputSelectClass(advInput.name)"
                     outlined
                     dense
                     hide-details
                     clearable
                     @keydown="clearTabindex"
                   ></v-select>
                   <v-text-field
                     v-else
                     v-model="advInput.value"
                     :ref="advInput.key"
                     @keyup.prevent="handleAdvInputKeyup"
                     :min="advInput.min ? advInput.min : -100000"
                     :max="advInput.max ? advInput.max : 100000"
                     class="form-input"
                     :disabled="advInput.disabled"
                     :label="$t(advInput.label)"
                     :placeholder="getPlaceholder(advInput)"
                     outlined
                     dense
                     hide-details
                     type="number"
                   >
                   </v-text-field>
                 </v-col>
               </v-row>
                <!-- //SSE Config -->
                <v-row v-if="notifications">
        <v-col cols="12">
        <h3 class="mt-5" style="padding-left: 0px;color: #0A2640;line-height: 1;font-weight: bold;">
          <u>{{ $t('SERVER SENT EVENT SETTINGS') }}</u></h3>
        <div class="row">
           <v-col>
          <div class="ml-1" style="margin-top: 24px;">
            <span v-if="notifications">
            <v-label> {{ $t('Picking') }} &nbsp;: </v-label>
            <v-switch inset hide-details solo class="form-switch"  v-model="picking" style="margin-left: 10px;
             vertical-align: sub;display: inline-block;"></v-switch>
            </span>
            <span class="ml-4" v-if="notifications">
              <v-label> {{ $t('Alarm') }} &nbsp;: </v-label>
              <v-switch inset hide-details solo class="form-switch"  v-model="alarm" style="margin-left: 10px;
              vertical-align: sub;display: inline-block;"></v-switch>
            </span>
          </div>
        </v-col>
        <v-col cols="7" class="mt-3">
          <v-text-field
                    class="form-input"
                    :label="$t('SSE Event URL for Subscription Details')"
                     placeholder="URL"
                     outlined
                     dense
                     hide-details
                     v-model="sseUrl"
                     disabled
                   >
          </v-text-field>
        </v-col>
        </div>

      </v-col>
    </v-row>

    <!-- //End SSE Config -->
             </v-expansion-panel-content>
           </v-expansion-panel>
         </v-expansion-panels>
       </v-card-text>
       <v-card-actions class="d-flex justify-center">
         <v-btn text icon @click="handleStoreAddBtnClick()" class="btn">{{
           $t("Save")
         }}</v-btn>
         <v-btn text icon @click="cancelAddStore()" class="btn">{{
           $t("Cancel")
         }}</v-btn>
       </v-card-actions>

   </v-card>
 </v-dialog>
</template>

<script>
// StorePopupAddSummary와 StoreSettingsModal은 같은 부분이 많이 mixin(storePopup.js)를 사용한다.
// Component 분리가 잘 되어있지 않아 수정이 힘든 상태며 추후 리팩토링시 v-for 부분을 모두 컴포넌트로 대체해야한다.
// 각 입력항목들은 선택가능하거나 이벤트가 걸려있거나 숨겨져있는 등 조건이 많기 때문에 리팩토링이 필요하다.
// 리팩토링 필요한 기능의 예시: '항목 숨김' 기능은 숨기고자 하는 항목에 css class로 적용되어 있으며(이름은 hidden) StorePopup.
// 에서 소스를 찾아볼 수 있다. 리펙토링시 custom directive를 사용하면 재사용가능하고 유지보수할 수 있게 사용가능할 것이다.

// 혹은 개별 조건을 storeInputs, advStoreInputs에 저장(disabled, hidden 등)하고 HTML에 custum directive로 저장된 개별 조건을 사용해서
// 각 항목별 조건을 적용하는 방법도 있다.

import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import configs from '@/plugins/configs'
import StorePopup from '@/mixins/StorePopup'

export default {
  mixins: [StorePopup],
  props: {
    user: Object,
    buttonDisabled: Boolean,
    areaAddDisabled: {
      type: Boolean
    }
  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      showAddStoreModal: false,
      CUSTOMER: configs.Customer,
      advancedSettingVisible: 1,
      countries: {},
      newAreas: [[], [], []],
      SingleUser: true,
      // storeInputs: [
      //   [ // 0
      //     {
      //       name: 'Company',
      //       key: 'company',
      //       label: 'Company',
      //       value: null,
      //       options: [],
      //       selectable: true,
      //       placeholderType: 'select'
      //     }
      //   ],
      //   [ // 1
      //     {
      //       name: 'Country',
      //       key: 'country',
      //       label: 'Country',
      //       value: null,
      //       options: [],
      //       selectable: true,
      //       placeholderType: 'select'
      //     },
      //     {
      //       name: 'Country',
      //       key: 'countryInput',
      //       label: 'Country',
      //       value: null,
      //       selectable: false,
      //       placeholderType: 'input'
      //     }
      //   ],
      //   [ // 2
      //     {
      //       name: 'Region',
      //       key: 'region',
      //       label: 'Region',
      //       value: null,
      //       options: [],
      //       selectable: true,
      //       placeholderType: 'select'
      //     },
      //     {
      //       name: 'Region',
      //       key: 'regionInput',
      //       label: 'Region',
      //       value: '',
      //       selectable: false,
      //       placeholderType: 'input'
      //     }
      //   ],
      //   [ // 3
      //     {
      //       name: 'City',
      //       key: 'city',
      //       label: 'City',
      //       value: null,
      //       options: [],
      //       selectable: true,
      //       placeholderType: 'select'
      //     },
      //     {
      //       name: 'City',
      //       key: 'cityInput',
      //       label: 'City',
      //       value: '',
      //       selectable: false,
      //       placeholderType: 'input'
      //     }
      //   ],
      //   [ // 4
      //     {
      //       name: 'Store Name',
      //       key: 'storeName',
      //       label: 'Store Name',
      //       value: null,
      //       selectable: false,
      //       placeholderType: 'input'
      //     },
      //     {
      //       name: 'Store Code',
      //       key: 'store',
      //       label: 'Store Code',
      //       value: null,
      //       selectable: false,
      //       placeholderType: 'input'

      //     }
      //   ],
      //   [ // 5
      //     {
      //       name: 'Store Description',
      //       key: 'storeDescription',
      //       label: 'Store Description',
      //       value: '',
      //       selectable: false,
      //       placeholderType: 'input'
      //     },
      //     {
      //       name: 'Time zone',
      //       key: 'zoneId',
      //       label: 'Time zone',
      //       value: null,
      //       options: [],
      //       selectable: true,
      //       placeholderType: 'select'
      //     }

      //   ],
      //   [ // 6
      //     {
      //       name: 'Time Server',
      //       key: 'ipNtpServer',
      //       label: 'Time Server',
      //       value: null,
      //       selectable: false,
      //       placeholderType: 'input'
      //     },
      //     {
      //       name: 'Whitelist',
      //       key: 'whiteListEnable',
      //       label: 'Whitelist',
      //       value: 'Disabled',
      //       options: ['Enabled', 'Disabled'],
      //       selectable: true,
      //       placeholderType: 'toggle'
      //     }
      //   ],
      //   [
      //     {
      //       name: 'LBS',
      //       key: 'lbsEnabled',
      //       label: 'LBS',
      //       value: 'Disabled',
      //       options: ['Enabled', 'Disabled'],
      //       selectable: true,
      //       placeholderType: 'toggle'
      //     }
      //   ]
      // ],

      storeInputs: [
        [
          // 0
          {
            name: 'Company',
            key: 'company',
            label: 'Company',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          }
        ],
        // 1
        [
          {
            name: 'Country',
            key: 'country',
            label: 'Country',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Region',
            key: 'region',
            label: 'Region',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'City',
            key: 'city',
            label: 'City',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          }
        ],
        [
          // 2
          {
            name: 'Country',
            key: 'countryInput',
            label: 'Country',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Region',
            key: 'regionInput',
            label: 'Region',
            value: '',
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'City',
            key: 'cityInput',
            label: 'City',
            value: '',
            selectable: false,
            placeholderType: 'input'
          }
        ],
        [
          // 3
          {
            name: 'Store Name',
            key: 'storeName',
            label: 'Store Name',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Store Code',
            key: 'store',
            label: 'Store Code',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Time zone',
            key: 'zoneId',
            label: 'Time zone',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          }
        ],
        [
          // 4

          {
            name: 'Time Server',
            key: 'ipNtpServer',
            label: 'Time Server',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Whitelist',
            key: 'whiteListEnable',
            label: 'Whitelist',
            value: 'Enabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          },
          {
            name: 'LBS',
            key: 'lbsEnabled',
            label: 'LBS',
            value: 'Disabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }
        ],
        [// 5
          {
            name: 'Store Description',
            key: 'storeDescription',
            label: 'Store Description',
            value: '',
            selectable: false,
            placeholderType: 'input'
          }
        ]
      ],
      newStoreInputs: [
        // 0
        [
          {
            name: 'Company',
            key: 'company',
            label: 'Company',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Market Landscape ID',
            key: 'store',
            label: 'marketLandscape ID',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Store Name',
            key: 'storeName',
            label: 'Store Name',
            value: null,
            selectable: false,
            placeholderType: 'input'
          }
        ],
        // 1
        [
          {
            name: 'Rplan',
            key: 'rpan',
            label: 'Rplan#',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Brand',
            key: 'groupId',
            label: 'Brand',
            value: null,
            selectable: true,
            placeholderType: 'select',
            options: [
              { value: 'Rogers', text: 'Rogers' },
              { value: 'FIDO', text: 'FIDO' },
              { value: 'Rogers in FIDO', text: 'Rogers in FIDO' },
              { value: 'FIDO in Rogers', text: 'FIDO in Rogers' }
            ]
          },
          {
            name: 'Banner',
            key: 'banner',
            label: 'Banner',
            value: null,
            selectable: true,
            options: [
              { value: 'corporate', text: 'Corporate' },
              { value: 'Dealers', text: 'Dealers' }
            ],
            placeholderType: 'select'
          }
        ],
        // 2
        [
          {
            name: 'Address',
            key: 'address',
            label: 'Address',
            value: null,
            options: [],
            selectable: false,
            placeholderType: 'input',
            class: 'col-8 !important'
          },
          // {
          //   name: 'Provide',
          //   key: 'province',
          //   label: 'Provide',
          //   value: null,
          //   options: [{ value: 'AB', text: 'AB' }],
          //   selectable: true,
          //   placeholderType: 'select'
          // },
          {
            name: 'Language',
            key: 'language',
            label: 'Language',
            value: null,
            options: [
              { value: 'En', text: 'En' },
              { value: 'FR', text: 'FR' },
              { value: 'BIL', text: 'BIL' }
            ],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Time zone',
            key: 'zoneId',
            label: 'Time zone',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          }
        ],
        // 3
        [
          {
            name: 'Country',
            key: 'country',
            label: 'Country',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Region',
            key: 'region',
            label: 'Region',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'City',
            key: 'city',
            label: 'City',
            value: null,
            options: [],
            selectable: true,
            placeholderType: 'select'
          }
        ],
        [
          // 4
          {
            name: 'Country',
            key: 'countryInput',
            label: 'Country',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Region',
            key: 'regionInput',
            label: 'Region',
            value: '',
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'City',
            key: 'cityInput',
            label: 'City',
            value: '',
            selectable: false,
            placeholderType: 'input'
          }
        ],
        // 5
        [
          // {
          //   name: 'Store Code',
          //   key: 'store',
          //   label: 'Store Code',
          //   value: null,
          //   selectable: false,
          //   placeholderType: 'input'
          // },

          {
            name: 'Time Server',
            key: 'ipNtpServer',
            label: 'Time Server',
            value: null,
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'Whitelist',
            key: 'whiteListEnable',
            label: 'Whitelist',
            value: 'Enabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          },
          {
            name: 'LBS',
            key: 'lbsEnabled',
            label: 'LBS',
            value: 'Disabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }
        ]
        // [
        //   {
        //     name: 'Address',
        //     key: 'address',
        //     label: 'Address',
        //     value: null,
        //     options: [],
        //     selectable: false,
        //     placeholderType: 'input',
        //     class: 'col-8 !important'
        //   }
        // ]
      ],
      advStoreInputs: [
        [ // 0
          {
            name: 'Lost Time',
            key: 'lostTime',
            label: 'Lost Time',
            value: '0',
            defaultValue: '0',
            creteria: 'Hour',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Logo Display Time',
            key: 'logoDisplayTime',
            label: 'Logo Display Time',
            value: '0',
            defaultValue: '0',
            creteria: 'Hour',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Beacon Loss Limit',
            key: 'beaconLossLimit',
            label: 'Beacon Loss Limit',
            value: '10',
            creteria: 'Count',
            defaultValue: '10',
            selectable: false,
            placeholderType: 'range'
          }
        ],
        [ // 1
          {
            name: 'Alive Check Interval',
            key: 'aliveInterval',
            label: 'Alive Check Interval',
            value: '24',
            creteria: 'Hour',
            defaultValue: '24',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Reactivation Retry Limit',
            key: 'reactivationRetryLimit',
            label: 'Reactivation Retry Limit',
            value: '2',
            creteria: 'Count',
            defaultValue: '2',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'Scan Period Set',
            key: 'scanPeriodSet',
            label: 'Scan Period Set',
            value: '0',
            creteria: 'Count',
            defaultValue: '0',
            selectable: false,
            placeholderType: 'range'
          }
        ],
        [ // 2
          {
            name: 'Icon Display Mode',
            key: 'batteryMode',
            label: 'Icon Display Mode',
            value: 0,
            defaultValue: 0,
            options: [0, 1, 2, 3, 4, 5, 6],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Icon Display Position',
            key: 'location',
            label: 'Icon Display Position',
            value: 'Right-Bottom',
            defaultValue: '',
            options: ['Right-Bottom', 'Right-Top', 'Left-Top', 'Left-Bottom'],
            selectable: true,
            placeholderType: 'select'
          },
          {
            name: 'Update Page Unlock',
            key: 'updatePageUnlock',
            label: 'Update Page Unlock',
            value: 'Disabled',
            defaultValue: 'Disabled',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }
        ],
        [ // 3. ※ Hidden inputs ※
          {
            name: 'AES',
            key: 'aesEnable',
            label: 'AES',
            value: 'Disabled',
            defaultValue: '',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          }

        ],
        [ // 4
          {
            name: 'Tap&Go Start Time',
            key: 'tapGoStartTime',
            label: 'Tap&Go Start Time',
            value: '9',
            defaultValue: '',
            selectable: false,
            placeholderType: 'own',
            placeholder: 'Range: 0 ~ 23',
            min: 0,
            max: 25,
            disabled: this.getTapAndGoDisabled()
          },
          {
            name: 'Tap&Go End Time',
            key: 'tapGoEndTime',
            label: 'Tap&Go End Time',
            value: '21',
            defaultValue: '',
            selectable: false,
            placeholderType: 'own',
            placeholder: 'Range: 0 ~ 23',
            min: 0,
            max: 25,
            disabled: this.getTapAndGoDisabled()
          },
          {
            name: 'Flexible Default',
            key: 'flexibleDefault',
            label: 'Flexible Default',
            value: '0',
            defaultValue: '0~23',
            selectable: false,
            placeholderType: 'input'
          },
          {
            name: 'ACS',
            key: 'acsEnable',
            label: 'ACS',
            value: 'Disabled',
            defaultValue: '',
            options: ['Enabled', 'Disabled'],
            selectable: true,
            placeholderType: 'toggle'
          },
          {
            name: 'Page Back Time',
            key: 'pageBackTime',
            label: 'Page Back Time',
            value: 0,
            defaultValue: 0,
            options: [0, 10, 20, 30, 40, 50, 60],
            selectable: true,
            placeholderType: 'input'
          },
          {
            name: 'ChAlive',
            key: 'chAlive',
            label: 'ChAlive',
            value: '78',
            creteria: 'Count',
            defaultValue: '78',
            selectable: false,
            placeholderType: 'range'
          },
          {
            name: 'ChLbs',
            key: 'chLbs',
            label: 'ChLbs',
            value: '78',
            creteria: 'Count',
            defaultValue: '78',
            selectable: false,
            placeholderType: 'range',
            disabled: true
          }
        ]
      ],
      initialStoreInputs: [],
      initialAdvStoreInputs: [],
      initialnewStoreInputs: [],
      keepStoreDescValue: '',

      // SSE
      notifications: false,
      picking: false,
      alarm: false,
      SSEEnabled: false,
      rabbitMqEnabled: '',
      sseUrl: ''
      // SSE END

    }
  },
  methods: {
    // handling special
    handleKeyUp (e) {
      commons.specialCharRest(e)
    },

    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    resetAddStoreInputs () {
      this.storeInputs = commons.copy(this.initialStoreInputs)
      this.advStoreInputs = commons.copy(this.initialAdvStoreInputs)
      this.newStoreInputs = commons.copy(this.initialnewStoreInputs)
    },
    setConditionsforChLbs (key) {
      if (key.key === 'lbsEnabled') {
        if (key.value === 'Enabled') {
          this.advStoreInputs[4][6].disabled = false
        }
        if (key.value === 'Disabled') {
          this.advStoreInputs[4][6].disabled = true
        }
      }
    },
    toggleAddStore () {
      this.showAddStoreModal = !this.showAddStoreModal
    },
    toggleAdvSetting () {
      this.advancedSettingVisible = this.advancedSettingVisible === 0 ? 1 : 0
    },
    getPlaceholder (input) {
      let placeholder = ''
      switch (input.placeholderType) {
        case 'select': placeholder = this.$t(`Select ${input.name.toLowerCase()}`)
          break
        case 'toggle': placeholder = this.$t('Enable/Disable')
          break
        case 'input': placeholder = this.$t(`Input ${input.name.toLowerCase()}`)
          break
        case 'own': placeholder = this.$t(input.placeholder)
          break
        case 'range': placeholder = `${this.$t('Criteria')}:${input.creteria}, ${this.$t('Default Value')}:${input.defaultValue}`
          break
        default: placeholder = this.$t(`Input ${input.name}`)
          break
      }
      return placeholder
    },
    // StoreInputs, advStoreInputs 정보를 변환, API Server에 요청가능한 형식으로 변환한다.
    async buildAddStoreReqBody (storeInputs, advStoreInputs) {
      const reqBody = {}
      const storeConfig = {}
      storeInputs = storeInputs.flat()
      advStoreInputs = advStoreInputs.flat()
      storeInputs = storeInputs.map(input => {
        if (input.placeholderType === 'toggle') {
          if (input.value === undefined || input.value === null || input.value.length < 1) return input
          input.value = this.enableToTrue(input.value)
        }
        if (this.isArea(input.key) && this.isAddArea(input.value)) {
          // 입력정보가 지역정보(Country, Region, City)이고, 값이 default 값인 경우.
          input.value = null
        }
        return input
      })
      advStoreInputs = advStoreInputs.map(input => {
        if (input.placeholderType === 'toggle') input.value = this.enableToTrue(input.value)
        return input
      })
      // area 정보 제외 http 요청 body에 담음(API 요청형식에 맞추기 위함).
      var data = []
      for (const input of storeInputs) {
        if (this.isAreaInput(input.key)) continue
        if (this.CUSTOMER === 'ROGERS') {
          if (input.key === 'province') {
            data.push({ province: input.value })
          }
          if (input.key === 'groupId') {
            data.push({ groupId: input.value })
          }
          if (input.key === 'banner') {
            data.push({ banner: input.value })
          }
          if (input.key === 'language') {
            data.push({ language: input.value })
          }
          if (input.key === 'company') {
            data.push({ company: input.value })
          }
          if (input.key === 'rpan') {
            data.push({ rPan: input.value })
          }
          if (input.key === 'address') {
            data.push({ address: input.value })
          }
          const mergedObject = data.reduce((result, current) => {
            return { ...result, ...current }
          }, {})
          this.keepStoreDescValue = JSON.stringify(mergedObject)
        }
        if (input.key === 'address') {
          reqBody.storeDescription = { value: input.value, name: 'Address' }
        } else {
          reqBody[input.key] = { value: input.value, name: input.name }
        }
      }
      if (this.CUSTOMER === 'ROGERS') {
        if (
          reqBody.groupId === 'Rogers' ||
         reqBody.groupId === 'Rogers in Fido'
        ) {
          reqBody.siteCode = '1234'
        } else {
          reqBody.siteCode = 'F1D0'
        }
      }
      for (const input of advStoreInputs) {
        storeConfig[input.key] = { value: input.value, name: input.name }
      }
      storeConfig.location.value = this.getLocationCode(storeConfig.location.value)
      reqBody.storeConfig = storeConfig
      return reqBody
    },
    async addStore () {
      if (this.CUSTOMER === 'ROGERS') {
        var reqBody1 = await this.buildAddStoreReqBody(
          commons.copy(this.newStoreInputs),
          commons.copy(this.advStoreInputs)
        )
      } else {
        var reqBody2 = await this.buildAddStoreReqBody(
          commons.copy(this.storeInputs),
          commons.copy(this.advStoreInputs)
        )
      }
      let reqBody = this.CUSTOMER === 'ROGERS' ? reqBody1 : reqBody2
      // let reqBody = await this.buildAddStoreReqBody(commons.copy(this.storeInputs), commons.copy(this.advStoreInputs))
      const isStoreConfigValid = await this.isStoreConfigValid(commons.copy(reqBody.storeConfig))
      const storeConfig = this.mapToValues(reqBody.storeConfig)
      delete reqBody.storeConfig
      const siteCode = reqBody.siteCode
      delete reqBody.siteCode
      if (this.CUSTOMER !== 'ROGERS') {
        var descriptionValue = reqBody.storeDescription
        delete reqBody.storeDescription
      }

      const isBodyValid = await this.isObjValid(reqBody)
      if (!isBodyValid || !isStoreConfigValid) return
      if (this.CUSTOMER !== 'ROGERS') {
        reqBody.storeDescription = descriptionValue
      }

      reqBody = this.mapToValues(reqBody)
      reqBody.storeConfig = storeConfig
      if (this.CUSTOMER === 'ROGERS') {
        reqBody.siteCode = siteCode
        reqBody.province = reqBody.region
        reqBody.storeDescription = this.keepStoreDescValue
      }
      console.log(reqBody)

      // SSE Config
      var arr = []
      if (this.alarm === true) {
        arr.push('ALARM_STATUS')
      }
      if (this.picking === true) {
        arr.push('PICKING_STATUS')
      }
      if (arr.length !== 0) {
        reqBody.serverSentEventTypes = arr
      } else {
        reqBody.serverSentEventTypes = null
      }
      // END SSE

      // if (
      //   reqBody.ipNtpServer.match(
      //     /^(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)\.(25[0-5]|2[0-4][0-9]|[1]?[1-9][1-9]?)$/
      //   )
      // ) {
      //   console.log('Enter valid Server IP')
      // } else {
      //   EventBus.$emit('messageAlert', 'Enter valid Server IP')
      //   return
      // }
      const config = { params: { company: this.user.company } }
      this.$utils
        .callAxiosWithBody(
          codes.requests.addStore.method,
          codes.requests.addStore.url,
          reqBody,
          config
        )
        .then(res => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          // Refresh ManagedStores
          EventBus.$emit('setManagedStores')
          this.$emit('fireGetStores')
          this.cancelAddStore()
          this.$store.dispatch('dataStore/addManagedStore', {
            company: reqBody.company,
            country: reqBody.country,
            region: reqBody.region,
            city: reqBody.city,
            code: reqBody.store,
            name: reqBody.storeName
          })
        })
        .catch(error => {
          if (error.response.data.responseCode === '402' || error.response.data.responseCode === '409' || error.response.data.responseCode === '503') {
            EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to save a new store.'))
          }
        })
    },
    async handleStoreAddBtnClick () {
      var accessmenu = this.$store.state.auth.user.accountInfo.accessMenu
      if (this.user.lbsEnable === 'true') {
        // 접속한 유저가 lbs 사용가능한 유저일때만 lbs settings도 추가한다.
        // const lbsEnabled = this.enableToTrue(this.storeInputs[7][0].value) || false
        let lbsEnabled
        if (this.CUSTOMER === 'ROGERS') {
          lbsEnabled =
         this.enableToTrue(this.newStoreInputs[5][2].value) || false
        } else {
          lbsEnabled =
         this.enableToTrue(this.storeInputs[4][2].value) || false
        }
        if (accessmenu.indexOf('6100') !== -1) {
          await this.setLbsConfiguration(lbsEnabled)
        }
      }
      this.addStore()
    },
    isAddBtn (val, idx) {
      if (Object.values(codes.msg.areaSelection)[idx] === val) return true
      return false
    },
    isAesAcs (key) {
      return (key === 'AES' || key === 'ACS')
    },
    getStoreInputTextClass (inputKey, idx) {
      let idxStr = ''
      let cls = 'form-input'
      if (this.isAreaInput(inputKey)) {
        cls = 'form-input in-btn'
        if (this.CUSTOMER !== 'ROGERS') {
          if (
            !this.isAddBtn(
              this.storeInputs[idx][
                inputKey === 'regionInput'
                  ? 1
                  : inputKey === 'cityInput'
                    ? 2
                    : 0
              ].value,
              inputKey === 'regionInput' ? 1 : inputKey === 'cityInput' ? 2 : 0
            )
          ) {
            // select 값이 있으면 hidden
            cls = 'form-input in-btn hidden'
          }
        } else {
          if (inputKey === 'countryInput') {
            idxStr = '01'
          } else if (inputKey === 'regionInput') {
            idxStr = '12'
          } else {
            idxStr = '23'
          }
          if (
            !this.isAddBtn(
              this.newStoreInputs[idx - 1][idxStr[0]].value,
              idxStr[1] - 1
            )
          ) {
            // select 값이 있으면 hidden
            cls = 'form-input in-btn hidden'
          }
        }
      }
      return cls
    },
    async initiateStorePopup () {
      await this.refreshAreas()
      if (this.productionType === codes.productionTypes.CLOUD) {
        const companies = await this.$utils.getCompanies(this.user.company)
        if (!commons.isNull(companies)) {
          this.storeInputs[0][0].options = companies
          this.storeInputs[0][0].value = companies[0]
        }
      } else {
        const company = await this.$utils.getCompany()
        if (!commons.isNull(company)) {
          this.storeInputs[0][0].options.push(company)
          this.storeInputs[0][0].value = company
          this.newStoreInputs[0][0].options.push(company)
          this.newStoreInputs[0][0].value = company
        }
      }
      this.initialStoreInputs = commons.copy(this.storeInputs)
      this.initialAdvStoreInputs = commons.copy(this.advStoreInputs)
      this.initialnewStoreInputs = commons.copy(this.newStoreInputs)
    },

    // SSE Cnnfig
    resetValues () {
      this.togetSSEConfig()
      // SSE
      this.notifications = false
      this.picking = false
      this.alarm = false
      this.SSEEnabled = false
      this.rabbitMqEnabled = ''
      this.sseUrl = ''
      // SSE END
    },
    setValue (KEY, Code) {
      if (KEY === 'store') {
        var serverDetail = this.toSetSSEURL(configs.ServerAddress)
        this.sseUrl = serverDetail + '/link/core/sse/' + Code
        console.log(this.sseUrl)
      }
    },
    toSetSSEURL (url) {
      if (url.endsWith(':')) {
        return url.slice(0, -1)
      } else {
        return url
      }
    },
    // SSE Configuration
    togetSSEConfig () {
      const url = '/api/common/config/SSE'
      const config = { params: {} }
      this.$utils.callAxios(
        'get',
        url, config).then((res) => {
        if (res.data) {
          console.log(res.data)
          this.notifications = res.data.enabled === 'true' || res.data.enabled === true
        }
      })
    }
    // End SSE Cnnfig
  },
  mounted () {
    this.SingleUser = this.user.serverType === 'SINGLE' // Or CENTRAL
    this.resetValues()
    this.initiateStorePopup()
  }
}
</script>

<style>
.hidden {
 display: none;
}
.new_design {
 padding: 35px 60px;
}

.new_design_color {
 background-color: #ececec;
 padding: 35px;
}

::v-deep.v-text-field--outlined > .v-input__control > .v-input__slot {
 background: #ffff !important;
}
</style>
